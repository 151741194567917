
import React, { useState, useEffect } from 'react';
import { storage } from '../firebase';
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadString } from 'firebase/storage';

import '../css/image-uploader.css';
import { httpsCallable } from 'firebase/functions'; // Import httpsCallable
import { getFunctions } from 'firebase/functions'; // Import getFunctions
import { getFirestore, doc, getDoc, updateDoc, increment, setDoc, collection  } from 'firebase/firestore';
import { arrayUnion, Timestamp } from 'firebase/firestore';

import { useAuth } from '../contexts/AuthContext';



const ImageUploader = ({ onUpload, disabled, currentUser }) => {

  const {credit, blockedCredit} = useAuth();
  const [progress, setProgress] = useState(0);
  const [inputName, setInputName] = useState('');
  const [inputSize, setInputSize] = useState('');

  const [loading, setLoading] = useState(false);

  const [totalCredits, setTotalCredits] = useState(0);
  
  //const [image, setImage] = useState(null);
  const [image, setImage] = useState([]);
  const [message, setMessage] = useState('');

  const [formVisible, setFormVisible] = useState(true);
  const [successMessage, setSuccessMessage] = useState(null);

  const functions = getFunctions(); // Initialize functions
  const copyImage = httpsCallable(functions, 'copyImage'); // Create a callable function

  //const [jobSizeLimit, setJobSizeLimit] = useState(0);


  const [inputSizes, setInputSizes] = useState([]);
  const [previewSrc, setPreviewSrc] = useState([]);

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const [isCreditSufficient, setIsCreditSufficient] = useState(true);
  const [isOrderSufficient, setIsOrderSufficient] = useState(true);

  const resizeImage = (file) => {
    return new Promise((resolve) => {
      const img = document.createElement("img");
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
  
        const MAX_WIDTH = 1280; // Max šírka obrázka
        const scaleFactor = MAX_WIDTH / img.width; // Koeficient škálovania
        canvas.width = MAX_WIDTH;
        canvas.height = img.height * scaleFactor;
  
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(resolve, file.type);
      };
    });
  };

  

  
  


  const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const length = 8;
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };

  useEffect(() => {
    if (totalCredits > credit - blockedCredit) {
      setIsCreditSufficient(false);
    } else {
      setIsCreditSufficient(true);
    }
  }, [totalCredits, credit, blockedCredit]);

  useEffect(() => {
    if (totalCredits > 20) {
      setIsOrderSufficient(false);
    } else {
      setIsOrderSufficient(true);
    }
  }, [totalCredits]);
  

  const handleChangeName = (e) => {
    setInputName(e.target.value);
  };

  const handleChangeSize = (e) => {
    setInputSize(e.target.value);
  };  

  const handleChange = async (e) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const allowedFormats = ['image/jpeg', 'image/png', 'image/jfif', 'image/bmp'];
  
      const validFiles = files.filter(file => allowedFormats.includes(file.type));
  
      if (validFiles.length !== files.length) {
        alert('Niektoré súbory majú nesprávny formát. Povolené formáty sú: jpg, jpeg, png, jfif a bmp.');
        return;
      }
  
      const resizedImages = await Promise.all(validFiles.map(resizeImage));
  
      setImage(resizedImages);
      setInputSizes(new Array(resizedImages.length).fill(1));
    }
  };
  

  const handleInputChangeSize = (e, index) => {
    const newInputSizes = [...inputSizes];
    newInputSizes[index] = parseInt(e.target.value);
    setInputSizes(newInputSizes);

      // Calculate the total credits whenever the versions of images change
    const total = newInputSizes.reduce((a, b) => a + b, 0);
    setTotalCredits(total);    
  };

  useEffect(() => {
    const total = inputSizes.reduce((a, b) => a + b, 0);
    setTotalCredits(total);
  }, [inputSizes]);
  
  

  const sendImageForProcessing = async () => {
    if (!image || image.length === 0 || !inputName ) {
      setMessage('Prosím, vyplňte všetky požadované informácie.');
      return;
    }
  /*
    if (image.length > jobSizeLimit) {
      setMessage(`Môžete nahrať maximálne ${jobSizeLimit} obrázok/ov naraz.`);
      return;
    }*/

    
  
    const jobName = generateRandomString();
  
    // Process each image in the image array
    await Promise.all(
      image.map(async (img, index) => {
    
        setLoading(true); // pred začiatkom operácie
    
        const randomString = generateRandomString();
    
        // Make sure img.name exists
        const imgName = img.name ?? `resized.${img.format || 'jpg'}`;
    
        const imageName = `${jobName}_input_${index}_${imgName
          .split('.')
          .slice(0, -1)
          .join('.')}_${randomString}_${currentUser.uid}.${imgName.split('.').pop()}`;
        const imagesFolderPath = `images`;
        const userJobsFolderPath = `users_jobs/${currentUser.uid}/${jobName}`;
    
        const logText = `JOB PARAMETERS\nname: ${inputName}\nnext seed: 0\n\nLOGS\nsubmited: ${new Date().toLocaleString()}, size: ${inputSizes[index]}, start_seed: 1`; // 4. Aktualizujte logText
        const logRef = ref(storage, `${userJobsFolderPath}/log_${index}.txt`);
        await uploadString(logRef, logText);
    
        try {
          // Upload to storage/images
          await uploadImageToFolder(img, imagesFolderPath, imageName);
    
          // Use the callable function to copy the image to storage/users_jobs/user_id/job_name
          await copyImage({
            sourcePath: `${imagesFolderPath}/${imageName}`,
            destinationPath: `${userJobsFolderPath}/${imageName}`,
          });
    
          onUpload(img);
        } catch (error) {
          console.error("Error uploading image:", error);
        }
      })
    );
    

    const db = getFirestore();

      // Create a reference to the "status" document
      const statusRef = doc(db, "status", "C2OGlCPYSyKqSfp3M8Uf"); // replace 'your_document_id_here' with your actual document id
      const creditRef = doc(db, "users", currentUser.uid);
      
      // Atomically increment the counter_in field in the "status" document
      await updateDoc(statusRef, {
        counter_in: increment(totalCredits)
      });
      await updateDoc(creditRef, {
        credit_blocked: increment(totalCredits)
      });

      // Vytvorenie nového objektu s údajmi o práci
      const current_date = Timestamp.now(); // vráti aktuálne časové razítko pre Firestore
      const jobsArray = {
        job_id: jobName, 
        date_created: current_date,
        job_name: inputName, // alebo akékoľvek ďalšie dáta, ktoré chcete pridať
        // ...
      };

      // Pridajte tento nový objekt do poľa "my_jobs" v databáze
      await updateDoc(creditRef, {
        my_jobs: arrayUnion(jobsArray)
      });


  
    setImage(null);
    setInputName('');
    setInputSize('');
    setTimeout(() => setLoading(false), 500); // počkať 500 ms pred skončením načítania
    setMessage('Úspešne odoslané na spracovanie');
  };
  


const uploadImageToFolder = async (file, folderPath, imageName) => {
  const storageRef = storage.ref(`${folderPath}/${imageName}`);
  const uploadTask = storageRef.put(file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        console.log(error);
        reject(error);
      },
      () => {
        resolve();
      }
    );
  });
};

const handleFileChange = (e) => {
  handleChange(e);
  const files = e.target.files;
  const previewSrcArray = [];

  if (files) {
    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onloadend = () => {
        previewSrcArray.push(reader.result);
        if (previewSrcArray.length === files.length) {
          setPreviewSrc(previewSrcArray);
        }
      };
      reader.readAsDataURL(files[i]);
    }
  }
};


const removeImage = (index) => {
  const newImages = image.filter((_, i) => i !== index);
  const newInputSizes = inputSizes.filter((_, i) => i !== index);
  setImage(newImages);
  setInputSizes(newInputSizes);
};

const handleSubmit = async () => {
  setFormVisible(false);
  await sendImageForProcessing();
  setTimeout(() => {
    setSuccessMessage('Obrázok bol úspešne odoslaný!');
  }, 1);
};

const resetForm = () => {
  setFormVisible(true);
  setPreviewSrc(null);
  setSuccessMessage(null);
  handleChangeName({ target: { value: '' } });
  handleChangeSize({ target: { value: 1 } });
};

if (loading) {
  return <div className="loading-ball"></div>;
}

return (
  <div className="container">
    <p>Váš dostupný kredit: {credit - blockedCredit} kreditov</p>

    {formVisible ? (
      <>
        <div className="progress-container">
          
        </div>
        <div className="input-container">
          <input
            type="file"
            onChange={handleFileChange}
            disabled={disabled}
            accept=".jpg,.jpeg,.png,.jfif,.bmp"
            className="input-file"
            multiple
          />
          {previewSrc && (
              <div className="preview-container">
                {image.map((img, index) => (
                  <div key={index} className="preview-wrapper">
                    <img src={URL.createObjectURL(img)} alt="Preview" className="preview-image" />
                    <button onClick={() => removeImage(index)} className="remove-image-button">
                      Odstrániť
                    </button>
                    <div className="input-group">
                      <label htmlFor={`inputSize_${index}`} className="input-label">
                        verzie:
                      </label>
                      <input
                        type="number"
                        id={`inputSize_${index}`}
                        value={inputSizes[index]}
                        onChange={(e) => handleInputChangeSize(e, index)}
                        className="input-text"
                        min="1"
                        max="20"
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}

          <div className="input-group">
            <label htmlFor="inputName" className="input-label">
              Názov projektu:
            </label>
            <input
              type="text"
              id="inputName"
              value={inputName}
              onChange={handleChangeName}
              className="input-text"
            />
          </div>

          <div className="credit-info">
            <p>Celková cena za objednávku: {totalCredits} kreditov</p>
            {!isCreditSufficient && <p style={{ color: 'red' }}>Nízky kredit</p>}
            {!isOrderSufficient && <p style={{ color: 'red' }}>Limit objednávky je 20 kreditov</p>}
          </div>

          <label>
            <input
              type="checkbox"
              checked={isCheckboxChecked}
              onChange={(e) => setIsCheckboxChecked(e.target.checked)}
            />
            Mám právo tieto fotografie upravovať
          </label>

          <button
            disabled={!inputName || !isCheckboxChecked || !isCreditSufficient || !isOrderSufficient || loading} 
            onClick={handleSubmit} 
            className="submit-button">
            Odošli
          </button>

          {message && <p className="message">{message}</p>}
        </div>
      </>
    ) : (
      <div className="success-container">
        <p className="success-message">{successMessage}</p>
        <button onClick={resetForm} className="reset-button">
          Zadať ďalšiu úlohu
        </button>
      </div>
    )}
  </div>
);
};

export default ImageUploader;

