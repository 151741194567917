import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { AuthProvider } from './contexts/AuthContext';
import { SubscriptionProvider } from './contexts/SubscriptionContext';
import PrivateRoute from './components/PrivateRoute';

import LoginPage from './pages/LoginPage';
import { RegisterPage } from './pages/RegisterPage';
import WelcomePage from './pages/WelcomeScreen';
import ReviewPage from './pages/ReviewScreen';
import { ConfirmRegistrationPage } from './pages/ConfirmRegistrationPage';
import DemoAppPage from './pages/DemoAppPage';
import { SubscriptionPage } from './pages/SubscriptionPage';
import UserDetail from './pages/UserDetail';
import ImageGalleryPage from './pages/ImageGalleryPage';
import ImageGalleryPageShared from './pages/ImageGalleryPageShared';
//import SubscriptionScreen from './pages/SubscriptionScreen';
//import SubscriptionOptions from './components/SubscriptionOptions';
import SubscriptionConfirmation from './pages/SubscriptionConfirmation';
import { InvitationsProvider } from './contexts/InvitationsContext';
import StripeCustomerPortal from './stripe/StripeCustomerPortal';
import TermsAndConditions from './pages/TermsAndConditions';
import Gdpr from './pages/Gdpr';
import Share from './pages/Share';
import SharingTable from './pages/SharingTable';
import ResetPassword from "./pages/ResetPassword";
import AdminProtectedPanel from './pages/AdminPanel';

import StripeDemo from './stripe/StripeDemo';
import SubsPublic from './stripe/SubsPublic';

function App() {
  return (
    <Container>
      <AuthProvider>
        <SubscriptionProvider>
          <InvitationsProvider>
            <Routes>
              <Route path="/" element={<WelcomePage />} />
              <Route path="/prehlad-nastrojov-pre-maklera" element={<ReviewPage />} />
              <Route index path="/cennik" element={<SubsPublic/>} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/reset" element={<ResetPassword />} />
              <Route path="/share" element={<Share />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/confirm-registration" element={<ConfirmRegistrationPage />} />
              <Route path="/terms" element={<TermsAndConditions />} />
              <Route path="/gdpr" element={<Gdpr />} />
              <Route path="/app" element={<PrivateRoute />} />
              <Route path="/subscription" element={<PrivateRoute> <SubscriptionPage /></PrivateRoute>} />
              <Route path="/projects" element={<PrivateRoute> <ImageGalleryPage /></PrivateRoute>} />
              <Route path="/shared" element={<PrivateRoute> <ImageGalleryPageShared /></PrivateRoute>} />
              <Route path="/account" element={<PrivateRoute> <UserDetail /></PrivateRoute>} />
              <Route index path="/subs" element={<PrivateRoute><StripeDemo/></PrivateRoute>} />
              
              <Route index path="/confirmation" element={<PrivateRoute><SubscriptionConfirmation /></PrivateRoute>} />
              <Route path="/app/" element={<PrivateRoute><DemoAppPage /></PrivateRoute>} />
              <Route path="/stripe" element={<PrivateRoute> <StripeDemo /></PrivateRoute>} />
              <Route path="/portal" element={<PrivateRoute> <StripeCustomerPortal /></PrivateRoute>} />
              <Route path="/admin" element={<PrivateRoute> <AdminProtectedPanel /></PrivateRoute>} />
              <Route path="/shares" element={<PrivateRoute> <SharingTable/></PrivateRoute>} />
            </Routes>
          </InvitationsProvider>
        </SubscriptionProvider>
      </AuthProvider>
    </Container>
  );
}

export default App;


