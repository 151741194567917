
import React, { useState } from 'react';
import { storage } from '../firebase';
import { v4 as uuidv4 } from 'uuid';
import { ref, uploadString, getDownloadURL, listAll, getMetadata, onMetadata} from 'firebase/storage';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

const ImageResubmitter = ({ onResubmit, disabled, currentPath, cesta, folder, index, inputImage}) => {
  const [progress, setProgress] = useState(0);
  const [inputSize, setInputSize] = useState('');
  const [message, setMessage] = useState('');
  const [imageName, setImageName] = useState('');
 
  const handleChangeSize = (e) => {
    setInputSize(e.target.value);
  };

  //const userJobsFolderPath = currentPath;

  /*
  const findInputImage = async () => {
    try {
      const folderRef = ref(storage, currentPath);
      const allFiles = await listAll(folderRef);
      const inputImageFile = allFiles.items.find((item) => item.name.includes('input_'));
      return inputImageFile;
    } catch (error) {
      console.error("Error finding input image:", error);
      return null;
    }
  };*/

  const resubmitJob = async () => {
    if (!inputSize) {
      setMessage('Prosím, vyplňte požadovanú veľkosť.');
      return;
    }
  
    try {
      // Retrieve original input image
      const inputImageFile = inputImage;
  
      if (!inputImageFile) {
        console.error(`Nepodarilo sa nájsť vstupný obrázok. adresa: ${currentPath}`);
        setMessage(`Nepodarilo sa nájsť vstupný obrázok. adresa: ${currentPath}`);
        return;
      }
  
      const logRef = ref(storage, `${cesta}${folder}/log_${index}.txt`);
      const submitRef = ref(storage, `/images/`);
      const logURL = await getDownloadURL(logRef);
      const getLogContent = httpsCallable(functions, 'getLogContent');
      const logText = (await getLogContent({ logURL })).data;
  
      console.log("Current content of log.txt:", logText);
  
      // Find the last line that starts with "finished"
      const finishedLine = logText.trim().split('\n').reverse().find(line => line.startsWith("finished"));
  
      if (!finishedLine) {
        console.log("Could not find 'finished' line in log.txt");
        return;
      }
  
      // Parse the seed from the "finished" line
      const seedMatch = finishedLine.match(/next seed: (\d+)/);
      //const nextSeed = seedMatch ? parseInt(seedMatch[1]) + 1 : 1;
      const nextSeed = seedMatch ? parseInt(seedMatch[1]) : 1;
  
      const resubmitLogLine = `submited: ${new Date().toLocaleString()}, size: ${inputSize}, start_seed: ${nextSeed}`;
      const temp_buffer = logText.endsWith('\n') ? `${logText}${resubmitLogLine}\n` : `${logText}\n${resubmitLogLine}`;
  
      console.log("Current content of log.txt:", logText);
      console.log("Updated content of log.txt:", temp_buffer);
  
      // Replace log.txt with the updated content from temp_buffer
      await uploadString(logRef, temp_buffer);
  
      setImageName (inputImage.name);
      const destinationPath = `images/${imageName}`;
      const copyImage = httpsCallable(functions, 'copyImage');
      await copyImage({ sourcePath: inputImageFile.fullPath, destinationPath });
  
      onResubmit(imageName);
    } catch (error) {
      console.error("Error resubmitting job:", error);
    }
  
    setInputSize('');
    setMessage('Úspešne odoslané na spracovanie');
  };
  
  
  return (
    <div style={{ fontFamily: 'Akrobat', margin: '1rem' }}>
      <h3>Pridať nové verzie</h3>
      <p>Cesta:  {cesta}</p>
      <p>Folder:  {folder}</p>
      <p>index:  {index}</p>
      <p>name:  {imageName}</p>
      <label htmlFor="inputSize" style={{ margin: '0.25rem' }}>Počet:</label>
      <input
        style={{ margin: '0.25rem', width:  '2rem', fontSize: '12pt'}}
        type="number"
        id="inputSize"
        value={inputSize}
        onChange={handleChangeSize}
        defaultValue={1} // tu je pridaná predvolená hodnota
      />
      <button className="input-button" onClick={resubmitJob}>Vygenerovať</button>
      {message && <p>{message}</p>}
    </div>
  );
}; 
  export default ImageResubmitter;
