import { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import { getFirestore, doc, setDoc, addDoc, collection, getDoc, getDocs, query, where } from 'firebase/firestore';
import { GoogleAuthProvider, signInWithPopup, signInWithPhoneNumber as firebaseSignInWithPhoneNumber, getAuth, signInWithCredential, sendPasswordResetEmail } from 'firebase/auth';


import { firestore  } from '../firebase';
import { updateDoc } from "firebase/firestore";

//import CryptoJS from "crypto-js";

const secretKey = "your-secret-key";

const AuthContext = createContext();


export function useAuth() {
  return useContext(AuthContext);
}



async function signInWithSMS(credential) {
  const auth = getAuth();
  try {
    
  //const confirmationResult = await firebaseSignInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);

  // Confirm the code entered by the user
  //const credential = await confirmationResult.confirm(otpCode);

  // Sign in the user with the obtained credential
  
  const result = await signInWithCredential(auth, credential);
  const user = result.user;

  // Create a new Firestore document for the user if it doesn't exist
  const db = getFirestore();
  const userRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userRef);

  if (!userDoc.exists()) {
    await setDoc(userRef, {
      account_name: user.displayName,
      credit: 20,
      monthly_credit: 20,
      credit_blocked: 0,
      account_type: 'Free',
      account_team_size: 0,
      email: user.email,
      uid: user.uid,
    });
  }

    return result;
  } catch (error) {
    console.error('Chyba pri prihlásení cez SMS:', error);
  }


}

const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error("Reset password error:", error.code, error.message);
    throw error;
  }
};


export function AuthProvider({ children }) {
  const [credit, setCredit] = useState(null);
  const [blockedCredit, setBlockedCredit] = useState(null);
  const [account_type, setAccountType] = useState(null);
  const [account_name, setAccountName] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSubUser, setIsSubUser] = useState(false);

  function login(email, password) {
    return auth.signInWithEmailAndPassword(email, password);
    
  }

  useEffect(() => {
    if (currentUser && currentUser.isSubUser !== undefined) {
      setIsSubUser(currentUser.isSubUser);
    }
  }, [currentUser]);
  


  function logout() {
    return auth.signOut();
  }

  async function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
  
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const db = getFirestore();
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
  
      if (!userDoc.exists()) {
        await setDoc(userRef, {
          account_name: user.displayName,
          credit: 20,
          monthly_credit: 20,
          credit_blocked: 0,
          account_type: 'Free',
          account_team_size: 0,
          email: user.email,
          uid: user.uid,
        });
      }
  
      return result;
    } catch (error) {
      console.error('Chyba pri prihlásení cez Google:', error);
    }
  }
  

  async function signup(email, password, account_type, account_name) {
    const result = await auth.createUserWithEmailAndPassword(email, password);
    const user = result.user;

    try {
      const db = getFirestore();
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);

      if (!userDoc.exists()) {
        await setDoc(userRef, {
          account_name: user.displayName,
          credit: 20,
          monthly_credit: 20,
          credit_blocked: 0,
          account_type: 'Free',
          account_team_size: 0,
          email: user.email,
          uid: user.uid,
        });
      }
  
      return result;
    } catch (error) {
      console.error('Chyba pri prihlásení cez Email:', error);
    }
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        //setCurrentUser({ ...user, isSubUser: false });
        setCurrentUser({
          ...user,
          uid: user.uid,
          email: user.email,
          isSubUser: false,
        });
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });
  
    return unsubscribe;
  }, []);
  
  useEffect(() => {
    const timer = setInterval(async () => {
      if (currentUser) {
        const db = getFirestore();
        const usersCollection = collection(db, 'users');
        const q = query(usersCollection, where('uid', '==', currentUser.uid));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userDocSnap = querySnapshot.docs[0];
          const data = userDocSnap.data();

          setCredit(data.credit);
          setBlockedCredit(data.credit_blocked);
        }
      } else {
        setCredit(null);
        setBlockedCredit(null);
      }
    }, 15000);  // Každých 15 sekúnd

    // TODO pravdepodobne existuje lepsie riesenie co sa znizenia poctu pristupov k DB tyka

    // Uvoľnenie zdrojov pri odmontovaní komponentu
    return () => clearInterval(timer);
  }, [currentUser]);  // Spustenie efektu len vtedy, keď sa zmení currentUser


  useEffect(() => {
    const fetchCredit = async () => {
      if (currentUser) {
        const db = getFirestore();
        const usersCollection = collection(db, 'users');
        const q = query(usersCollection, where('uid', '==', currentUser.uid));
        const querySnapshot = await getDocs(q);
  
        if (!querySnapshot.empty) {
          const userDocSnap = querySnapshot.docs[0];
          const data = userDocSnap.data();
  
          setCredit(data.credit);
          setBlockedCredit(data.credit_blocked);
          setAccountType(data.account_type);
          setAccountName(data.account_name);
        }
      } else {
        setCredit(null);
        setBlockedCredit(null);
        setAccountType(null);
        setAccountName(null);
      }
    };
  
    fetchCredit();
  }, [currentUser]);
  

  function updateCredit(newCredit) {
    setCredit(newCredit);
  }

  useEffect(() => {
    const fetchSubscription = async () => {
      if (currentUser) {
        const db = getFirestore();
        
        // Načítať predplatné používateľa
        const subscriptionsRef = collection(db, 'users', currentUser.uid, 'subscriptions');
        const subscriptionsQuery = query(subscriptionsRef, where('status', '==', 'active'));
        const activeSubscriptions = await getDocs(subscriptionsQuery);
        
        // Ak existuje aspoň jedno aktívne predplatné
        if (!activeSubscriptions.empty) {
          // Použijem prvé aktívne predplatné (môžete prispôsobiť podľa potreby)
          const subscriptionData = activeSubscriptions.docs[0].data();

          
          // Získať hodnotu z prvku pola "items"
          const accountType = subscriptionData.items[0].price.product.name;

          // Aktualizovať hodnotu account_type v dokumente používateľa
          const userRef = doc(db, 'users', currentUser.uid);
          await updateDoc(userRef, { account_type: accountType });

          // Získať hodnotu team size
          const teamSize = subscriptionData.items[0].price.product.metadata.team_size;

          // Aktualizovať hodnotu account_type v dokumente používateľa
          const teamSizeNumber = parseInt(teamSize, 10);
          await updateDoc(userRef, { account_team_size: teamSizeNumber });

          // Získať hodnotu monthly_credit
          const monthlyCredit = subscriptionData.items[0].price.product.metadata.credit;

          // Aktualizovať hodnotu account_type v dokumente používateľa
          const monthlyCreditNumber = parseInt(monthlyCredit, 10);
          await updateDoc(userRef, { monthly_credit: monthlyCreditNumber });
  
          // Lokálne aktualizovať stav
          setAccountType(accountType);
        }
      }
    };
  
    // Spustiť asynchrónnu funkciu
    fetchSubscription();
  }, [currentUser]);
  

  const value = {
    currentUser,
    login,
    signInWithGoogle,
    signInWithSMS,
    signup,
    logout,
    credit,
    blockedCredit,
    account_type,
    account_name,
    updateCredit, 
   // loginAsSubUser,
    setIsSubUser,
    isSubUser,
    resetPassword,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
