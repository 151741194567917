import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

function DaysToReset({ userId }) {
  const [daysToReset, setDaysToReset] = useState(null);

  useEffect(() => {
    const fetchDateReset = async () => {
      const db = getFirestore();
      const userDocRef = doc(db, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const dateReset = userData.date_reset;
        const currentDate = new Date();
        const resetDate = new Date(dateReset.seconds * 1000);
        const diffTime = resetDate - currentDate;
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        setDaysToReset(diffDays);
      }
    };

    if (userId) {
      fetchDateReset();
    }
  }, [userId]);

  const progressPercentage = daysToReset !== null ? (daysToReset / 30) * 100 : 0;
  const overPercentage = progressPercentage > 100 ? progressPercentage - 100 : 0;

  return (
    <div>
      <p>Počet dní do resetu: {daysToReset}</p>
      <progress max="100" value={progressPercentage - overPercentage}></progress>
      {overPercentage > 0 && (
        <>
          <progress max="100" value={overPercentage}></progress>
        </>
      )}
    </div>
  );
}

export default DaysToReset;
