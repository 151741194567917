import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { firestore } from "../firebase";
import { Navbar, Nav } from 'react-bootstrap';
import '../css/nav-bar.css';

function NavBar() {
  const { currentUser, logout, credit, blockedCredit, account_type, account_name, isSubUser } = useAuth();
  const [activeInvitationsCount, setActiveInvitationsCount] = useState(0);

  useEffect(() => {
    const loadActiveInvitationsCount = async () => {
      if (currentUser) {
        const currentUserRef = firestore.collection("users").doc(currentUser.uid);
        const currentUserSnapshot = await currentUserRef.get();
        const userData = currentUserSnapshot.data();
        const activeInvitationsData = userData.active_invitations || [];
        setActiveInvitationsCount(activeInvitationsData.length);
      }
    };
    loadActiveInvitationsCount();
  }, [currentUser, activeInvitationsCount]);

  const navigate = useNavigate();
  const location = useLocation();

  async function handleLogout() {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Chyba pri odhlásení:', error);
    }
  }
  

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="nav-bar">
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto navbar-left">
          <Nav.Item><Link to="/app" className={location.pathname === '/' ? 'nav-link nav-link-active navbar-link' : 'nav-link navbar-link'}>APPka </Link></Nav.Item>
          <Nav.Item><Link to="/projects" className={location.pathname === '/projects' ? 'nav-link nav-link-active navbar-link' : 'nav-link navbar-link'}>
          Projekty</Link></Nav.Item>
          {activeInvitationsCount > 0 && (
            <Nav.Item><Link to="/shared" className={location.pathname === '/shared' ? 'nav-link nav-link-active navbar-link' : 'nav-link navbar-link'}>Projekty v tíme</Link></Nav.Item>
          )}
          
          <Nav.Item> <Link to="/subs" className={location.pathname === '/subs' ? 'nav-link nav-link-active navbar-link' : 'nav-link navbar-link'}>Predplatné</Link></Nav.Item>  
        </Nav>
        
        <Nav className="ml-auto navbar-right navbar-account-wrapper">
          <div className="navbar-account-details">
            {account_name !== null && <span className="navbar-account-name"> {account_name}</span>}

            {currentUser && <span className="navbar-credit">Kredit: {credit}</span>}
            {currentUser && <span className="navbar-credit">Blokované: {blockedCredit}</span>}
          </div>
          <Nav.Item><Link to="/account" className={location.pathname === '/account' ? 'nav-link nav-link-active navbar-link' : 'nav-link navbar-link'}>Môj profil</Link></Nav.Item>
          <Nav.Link onClick={handleLogout} className="navbar-logout">Odhlásiť sa</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
  
}

export default NavBar;



//            {account_type !== null && <span className="navbar-account-type">Typ účtu: {account_type}</span>}