import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Footer from '../components/Footer';

export function SubscriptionPage() {
  return (
    <Container className="mt-5">
      <h1>Preskúmať predplatné</h1>
      <Row className="mt-3">
        <Col>
          <Card>
            <Card.Header>
              <h3>Plán základný</h3>
            </Card.Header>
            <Card.Body>
              <h4 className="text-muted">30 dní zdarma</h4>
              <ul>
                <li>Obmedzený prístup k funkcionalite aplikácie</li>
                <li>Podpora iba pre základné funkcie</li>
              </ul>
              <Button variant="primary" className="mt-3">
                Získať prístup
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Header>
              <h3>Plán rozšírený</h3>
            </Card.Header>
            <Card.Body>
              <h4 className="text-muted">30 dní zdarma</h4>
              <ul>
                <li>Úplný prístup k funkcionalite aplikácie</li>
                <li>Podpora pre všetky funkcie</li>
                <li>24/7 technická podpora</li>
              </ul>
              <Button variant="primary" className="mt-3">
                Získať prístup
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
