
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import 'firebase/compat/analytics';

import { getFunctions } from "firebase/functions";


const firebaseConfig = {
  apiKey: "AIzaSyC92Gn4ckXsA52nH9a2AFkFajzqmnlJHw8",
  authDomain: "react-002-6aaf0.firebaseapp.com",
  databaseURL: "https://react-002-6aaf0-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "react-002-6aaf0",
  storageBucket: "react-002-6aaf0.appspot.com",
  messagingSenderId: "138376352106",
  appId: "1:138376352106:web:25d880e997841a5eabd513",
  measurementId: "G-DXEG0FHDWY" // Add this line with your Measurement ID.
};

firebase.initializeApp(firebaseConfig);

export const storage = firebase.storage();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const functions = getFunctions();
export const analytics = firebase.analytics;

export default firebase;

