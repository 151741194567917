import React, { useState, useEffect } from 'react';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import '../css/general-page.css';


function BillingDetailsModal({ onClose }) {
  const { currentUser } = useAuth();
  const firestore = getFirestore();

  const [loading, setLoading] = useState(true);
  const [billing_businessName, setBusinessName] = useState('');
  const [billing_ico, setIco] = useState('');
  const [billing_address_street, setAddressStreet] = useState('');
  const [billing_address_city, setAddressCity] = useState('');
  const [billing_address_zip, setAddressZip] = useState('');
  const [billing_contactPerson_name, setContactPersonName] = useState('');
  const [billing_contactPerson_phone, setContactPersonPhone] = useState('');
  const [editMode, setEditMode] = useState(false); // Track the edit mode state

  useEffect(() => {
    const fetchUserData = async () => {
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setBusinessName(userDoc.data().billing_businessName || '');
        setIco(userDoc.data().billing_ico || '');
        setAddressStreet(userDoc.data().billing_address_street || '');
        setAddressCity(userDoc.data().billing_address_city || '');
        setAddressZip(userDoc.data().billing_address_zip || '');
        setContactPersonName(userDoc.data().billing_contactPerson_name || '');
        setContactPersonPhone(userDoc.data().billing_contactPerson_phone || '');
      }

      setLoading(false);
    };

    fetchUserData();
  }, [currentUser.uid, firestore]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    const userDoc = doc(firestore, 'users', currentUser.uid);

    await setDoc(userDoc, {
      billing_businessName,
      billing_ico,
      billing_address_street,
      billing_address_zip,
      billing_address_city,
      billing_contactPerson_name,
      billing_contactPerson_phone
    }, { merge: true });

    setLoading(false);
    setEditMode(false); // Disable edit mode after saving
  };

  const handleEdit = () => {
    setEditMode(true); // Enable edit mode
  };

  if (loading) {
    return <div className="loading-ball"></div>;
  }

  return (
    <div className="billing-details-modal">
      <h3>Fakturačné a kontaktné údaje</h3>
        <form onSubmit={handleSubmit} className="billing-details-form">
        
            <div className="billing-details-column">                
                <div>
                    <label htmlFor="billing_businessName" className="billing-details-label">
                        Obchodné meno:
                    </label>
                    <input
                        type="text"
                        id="billing_businessName"
                        value={billing_businessName}
                        onChange={(e) => setBusinessName(e.target.value)}
                        placeholder="Obchodné meno"
                        disabled={!editMode}
                        className="billing-details-input"
                    />
                </div>
                <div>
                    <label htmlFor="billing_ico" className="billing-details-label">
                        IČO:
                    </label>
                    <input
                        type="text"
                        id="billing_ico"
                        value={billing_ico}
                        onChange={(e) => setIco(e.target.value)}
                        placeholder="IČO"
                        disabled={!editMode}
                        className="billing-details-input"
                    />
                </div>
                <div>
                    <label htmlFor="billing_address_street" className="billing-details-label">
                        Ulica:
                    </label>
                    <input
                        type="text"
                        id="billing_address_street"
                        value={billing_address_street}
                        onChange={(e) => setAddressStreet(e.target.value)}
                        placeholder="Ulica"
                        disabled={!editMode}
                        className="billing-details-input"
                    />
                </div>
                <div>
                    <label htmlFor="billing_address_city" className="billing-details-label">
                        Mesto:
                    </label>
                    <input
                        type="text"
                        id="billing_address_city"
                        value={billing_address_city}
                        onChange={(e) => setAddressCity(e.target.value)}
                        placeholder="Mesto"
                        disabled={!editMode}
                        className="billing-details-input"
                    />
                </div>
            </div>
            <div className="billing-details-column">
                <div>
                    <label htmlFor="billing_address_zip" className="billing-details-label">
                        PSČ:
                    </label>
                    <input
                        type="text"
                        id="billing_address_zip"
                        value={billing_address_zip}
                        onChange={(e) => setAddressZip(e.target.value)}
                        placeholder="PSČ"
                        disabled={!editMode}
                        className="billing-details-input"
                    />
                </div>
                <div>
                    <label htmlFor="billing_contactPerson_name" className="billing-details-label">
                        Kontaktná osoba:
                    </label>
                    <input
                        type="text"
                        id="billing_contactPerson_name"
                        value={billing_contactPerson_name}
                        onChange={(e) => setContactPersonName(e.target.value)}
                        placeholder="Kontaktná osoba"
                        disabled={!editMode}
                        className="billing-details-input"
                    />
                </div>
                <div>
                    <label htmlFor="billing_contactPerson_phone" className="billing-details-label">
                        Telefón:
                    </label>
                    <input
                        type="text"
                        id="billing_contactPerson_phone"
                        value={billing_contactPerson_phone}
                        onChange={(e) => setContactPersonPhone(e.target.value)}
                        placeholder="Telefón"
                        disabled={!editMode}
                        className="billing-details-input" 
                    />
                </div>
                <div className="billing-details-actions">
                {!editMode && (
                    <button className="input-button" type="button" onClick={handleEdit}>
                        Upraviť
                    </button>
                )}
                {editMode && (
                    <button className="input-button" type="submit">Uložiť</button>
                )}
            </div>
            </div>
            
        </form>

        <hr></hr>
    </div>
);

}

export default BillingDetailsModal;
