import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { useSwipeable } from 'react-swipeable';
import ImageResubmitter from '../components/ImageResubmiter';
import '../css/custom-modal.css';
import { getStorage, ref, listAll, deleteObject, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { useCallback } from 'react';
import { collection, addDoc, query, where, getDocs, serverTimestamp } from "firebase/firestore";
import { firestore } from '../firebase'; // Importujte konfiguráciu Firestore
import { LazyLoadImage } from 'react-lazy-load-image-component';


const CustomModalImage = ({ cesta, small, large = [], input = [], onDelete, onAddVersions, currentUser, folder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const [isInitial, setIsInitial] = useState(true);

  const [largeImages, setLargeImages] = useState(large);
  const [inputImages, setInputImages] = useState(input);
  const [smallImages, setSmallImages] = useState(small);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeletedMessage, setShowDeletedMessage] = useState(false);

  const [imageName, setImageName] = useState('');
  const [inputIndex, setInputIndex] = useState(-1);

  const [showOriginal, setShowOriginal] = useState(false);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const shareInputRef = useRef(null);

  const openShareModal = () => {
    setIsShareModalOpen(true);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  const handleCopyClick = () => {
    if (shareInputRef.current) {
      shareInputRef.current.select();
      document.execCommand('copy');
    }
  };

  const shareLinkRef = useRef(null);

  const handleShareProject = async () => {
    
    openShareModal();

    try {
      // Create a reference to the "sharing" collection
      const sharingCollectionRef = collection(firestore, "sharing");
  
      // Create a query to check for existing documents with the same "folder" value
      const q = query(sharingCollectionRef, where("folder", "==", folder));
  
      // Execute the query to get matching documents
      const querySnapshot = await getDocs(q);
  
      // If a document with the same "folder" value already exists, don't create a new one
      if (!querySnapshot.empty) {
        console.log(`Document with folder "${folder}" already exists.`);
        return;
      }
  
      // If no existing document found, create a new document in the "sharing" collection
      await addDoc(sharingCollectionRef, {
        date_shared: serverTimestamp(),
        owner: currentUser,
        root_folder: "users_jobs",
        folder: folder
      });
  
     
    } catch (error) {
      console.error("Error sharing project:", error);
    }
  };

  const navigate = useNavigate();

  const openDeleteModal = () => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const openAddModal = () => {
    setIsAddModalOpen(true);
    const imagePath = large[currentIndex].fullPath
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };


  useEffect(() => {
    setShowOriginal(false);
    const fullString = large[currentIndex]?.split('output_').pop();
    if (fullString) {
      const firstSegment = fullString.split('_')[0];
      setInputIndex(Number(firstSegment));
      setImageName(fullString);
    }
  }, [currentIndex, large]);

  const handleDeleteConfirm = () => {
    setIsDeleting(true);
    deleteFolderContents(`users_jobs/${currentUser}/${folder}`)
      .then(() => {
        setIsDeleting(false);
        setShowDeletedMessage(true);
        setTimeout(() => setShowDeletedMessage(false), 1500);
        //reloadFolders();
      });
      
  };

  const handleDownloadAll = async () => {
    const zip = new JSZip();
  
    // Prejdite cez všetky URL obrázkov a pridajte ich do zip súboru
    for (let i = 0; i < largeImages.length; i++) {
      const url = largeImages[i];
  
      const response = await fetch(url);
      const blob = await response.blob();
  
      // Vložte blob do zip súboru ako súbor
      zip.file(`image${i}.jpeg`, blob, { binary: true });
    }
  
    // Vytvoríte zip súbor a stiahnite ho
    const zipFile = await zip.generateAsync({ type: "blob" });
    saveAs(zipFile, `inolens-export-${folder}-${new Date().toLocaleDateString()}.zip`);
  };

  const handleDownload = async () => {
    const imageSrc = largeImages[currentIndex];
  
    // Fetch obrázok zo servera
    const response = await fetch(imageSrc);
    
    // Skontrolujte, či požiadavka prebehla úspešne
    if (!response.ok) {
      console.error(`Failed to fetch image from ${imageSrc}`);
      return;
    }
  
    // Získať blob (binárny objekt) z odpovede
    const blob = await response.blob();
    
    // Uložiť blob ako súbor do zariadenia používateľa
    saveAs(blob, 'download.jpeg');
  };

  const handleDownloadClick = () => {
    fetch(large[currentIndex])
      .then(response => response.blob())
      .then(blob => {
        const imageUrl = new URL(large[currentIndex]);
        //const fileName = imageUrl.pathname.split('/').pop();
        const timeStamp = new Date().getTime();
        const newFileName = `INOLENS-export-${timeStamp}.jpeg`;
        saveAs(blob, newFileName);
      })
      .catch(e => console.error(e));
  }
  

  const handleModalClose = () => {
    closeModal();
  }

  const handleShowOriginalToggle = () => {
    setShowOriginal(!showOriginal);
  };


  useEffect(() => {
    if (showDeletedMessage) {
      const timer = setTimeout(() => {
        setShowDeletedMessage(false);
        closeModal(); 
        closeDeleteModal(); // funkcia na zatvorenie modálneho okna
        }, 1500);
  
      // Clean up function to clear the timeout if the component unmounts before the timeout finishes
      return () => clearTimeout(timer);
    }
  }, [showDeletedMessage, navigate]);
  
  
  
  useEffect(() => {
    setLargeImages(large);
  }, [large]);

  useEffect(() => {
    setInputImages(input);
  }, [input]);
  
  
  useEffect(() => {
    setSmallImages(small);
  }, [small]);

  useEffect(() => {
    console.log('large updated:', largeImages);
  }, [largeImages]);

  useEffect(() => {
    console.log('input updated:', inputImages);
  }, [inputImages]);

  useEffect(() => {
    if (isInitial) {
      const index = largeImages.indexOf(smallImages);
      setCurrentIndex(index !== -1 ? index : (largeImages.length > 0 ? 0 : -1));
      setIsInitial(false);
    }
  }, [smallImages, largeImages, isInitial]);

  useEffect(() => {
    if (isOpen) {
      const index = largeImages.indexOf(smallImages);
      setCurrentIndex(index !== -1 ? index : (largeImages.length > 0 ? 0 : -1));
    }
  }, [isOpen, smallImages, largeImages]);

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const goNext = useCallback(() => {
    setCurrentIndex((currentIndex + 1) % largeImages.length);
  }, [currentIndex, largeImages.length]); // závislosti

  const goPrev = useCallback(() => {
    setCurrentIndex((currentIndex - 1 + largeImages.length) % largeImages.length);

    const fullString = large[currentIndex].split('output_').pop();
    const firstSegment = fullString.split('_')[0];
    setInputIndex(firstSegment);
    setImageName (fullString);

  }, [currentIndex, largeImages.length]); // závislosti


  const handleSwipedLeft = () => {
    goNext();
  };

  const handleSwipedRight = () => {
    goPrev();
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleSwipedLeft,
    onSwipedRight: handleSwipedRight,
    trackMouse: true,
    preventDefaultTouchmoveEvent: true,
  });

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (isOpen) {
        if (event.key === 'ArrowRight') {
          goNext();
        } else if (event.key === 'ArrowLeft') {
          goPrev();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, goNext, goPrev]);

  if (typeof document !== 'undefined') {
    Modal.setAppElement('#root');
  }

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  
  const handleDelete = async () => {
    if (onDelete) {
    const deletedImage = largeImages[currentIndex];
    await onDelete(deletedImage);

  // Remove the image and its thumbnail from 'large' and 'small'
  const newLargeImages = largeImages.filter((image) => image !== deletedImage);
  const newSmallImages = smallImages.filter((image) => image !== deletedImage);
  setLargeImages(newLargeImages);
  setSmallImages(newSmallImages);

  if (newLargeImages.length === 0) {
    setCurrentIndex(-1);
  } else if (currentIndex === newLargeImages.length) {
    setCurrentIndex(0);
  } else {
    setCurrentIndex(currentIndex);
  }

  console.log('Deleted image:', deletedImage);
  console.log('New large images:', newLargeImages);
  console.log('New small images:', newSmallImages);
}
};

const handleAddVersions = () => {
if (onAddVersions) {
onAddVersions();
}
};

const deleteFolderContents = async (path) => {
  const storage = getStorage();
  const storageRef = ref(storage, path);

  listAll(storageRef)
    .then(dir => {
      dir.items.forEach(fileRef => {
        deleteFile(fileRef.fullPath);
      });
      dir.prefixes.forEach(folderRef => {
        deleteFolderContents(folderRef.fullPath);
      })
    })
    .catch(error => {
      console.log(error);
    });
}

const deleteFile = async (pathToFile) => {
  const storage = getStorage();
  const fileRef = ref(storage, pathToFile);

  deleteObject(fileRef).catch(function(error) {
    console.log(error);
  });
}



return (
<>

  <LazyLoadImage src={small} alt="Thumbnail" onClick={() => openModal(large.indexOf(small))} className="thumbnail-image"  />
  <Modal
    isOpen={isOpen} onRequestClose={closeModal} className="modal-content"
  >
   

    <div className="modal-image-container">

      {currentIndex !== -1 && (
        <img
        {...swipeHandlers}
        src={showOriginal ? input[inputIndex] : large[currentIndex]}
        alt="Fullsize"
        className="modal-image"
        />
        
      )}

    <div className="modal-image-actions">
      
    

      <button onClick={handleShowOriginalToggle} className="action-button" title='Zobraziť/skryť originál'>
      <i class="fa fa-eye-slash" aria-hidden="true"></i> 
      </button>

      <button onClick={handleDownloadClick} className="action-button" title="Stiahnúť obrázok">
      <i class="fa fa-arrow-down" aria-hidden="true"></i>
      </button>

      <button onClick={handleModalClose} className="action-button" title="Späť">
      <i class="fa fa-reply" aria-hidden="true"></i>
      </button>

    </div>

      <button onClick={goPrev} className="modal-button modal-button-prev" > {'<'} </button>
      <button onClick={goNext} className="modal-button modal-button-next" > {'>'} </button>
    </div>


    <div className="thumbnail-container">
      {large.map((thumbnail, index) => (             
      <img key={index} src={thumbnail} alt={`Thumbnail ${index + 1}`} onClick={() => handleThumbnailClick(index)}
          className={`thumbnail ${currentIndex === index ? 'active-thumbnail' : ''}`}
        />
      ))}
    </div>

    <div >
      <button onClick={handleDelete} style={{ display: 'none' }}> Zmazať </button>  
    </div>
    <div >
    <button  onClick={openDeleteModal} className="input-button" title="Zmazať celý priečinok"> 
    <i class="fa fa-trash" aria-hidden="true"></i> Zmazať priečinok
    </button>
    
    <button onClick={handleDownloadAll} className="input-button" title="Stiahnúť všetky výstupy">
     <i class="fa fa-arrow-down" aria-hidden="true"></i> Stiahnúť všetky ako zip
    </button>

    <button onClick={handleShareProject} className="input-button" title="Zdieľať projekt">
        Zdieľať projekt
      </button>

    </div>





  </Modal>

  <Modal isOpen={isShareModalOpen} onRequestClose={closeShareModal} className="modal-content">
        <h2>Zdieľať projekt</h2>
        <input
          ref={shareInputRef}
          type="text"
          value={`https://inolens.sk/share?f=${folder}`}
          readOnly
        />
        <button onClick={handleCopyClick}>Skopírovať</button>
        <button onClick={closeShareModal}>Zavrieť</button>
      </Modal>

  <Modal isOpen={isDeleteModalOpen} onRequestClose={closeDeleteModal} className="modal-content">
  {isDeleting && <p>Zmazávam...</p>}
  {!isDeleting && !showDeletedMessage && (
    <>
      <h2>Upozornenie</h2>
      <p>Zmazanie priečinka je nezvratná operácia. Naozaj chcete pokračovať?</p>
      <button className="input-button" onClick={handleDeleteConfirm}>Rozumiem</button>
      <button className="input-button" onClick={closeDeleteModal}>Zrušiť</button>
    </>
  )}
  {showDeletedMessage && <p>Zmazané</p>}
</Modal>

<Modal isOpen={isAddModalOpen} onRequestClose={closeAddModal} className="modal-resubmit">
    <p>Cesta:  {cesta}</p>
    <p>index:  {inputIndex}</p>
    <ImageResubmitter inputImage = {input[inputIndex]} index = {inputIndex} cesta = {cesta} folder = {folder}/>
</Modal>

</>
);
};

export default CustomModalImage;
