// SubscriptionContext.js
import { createContext, useContext, useState } from "react";

const SubscriptionContext = createContext();

export function useSubscription() {
  return useContext(SubscriptionContext);
}

export function SubscriptionProvider({ children }) {
  const [AccountType, setAccountType] = useState(null);
  const [userId, setUserId] = useState(null);

  const value = {
    AccountType,
    setAccountType,
    userId,
    setUserId,
  };

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
}