import React from 'react';
import '../css/general-page.css';
import { Link } from 'react-router-dom';

function Footer() {
  const linkStyle = { color: 'white', textDecoration: 'none', cursor: 'pointer' };
  return (
    <footer className="footer">
      
      <p style={{ color: 'white', fontSize: '11pt' }}>
        &copy; {new Date().getFullYear()} - 
        <Link to="/" className="footer-link" style={linkStyle}> INOLENS </Link> 
        | <a href="mailto:info@inolens.sk?subject=INOLENS - kontakt z webu" className="footer-link" style={linkStyle}>info@inolens.sk </a> 
        | <a href="tel:0907223859" className="footer-link" style={linkStyle}>+421 907 223 859 </a> 
        | <Link to="/terms" className="footer-link" style={linkStyle}>Podmienky </Link> 
        | <Link to="/gdpr" className="footer-link" style={linkStyle}>GDPR</Link>
      </p>
     
    </footer>
  );
}

export default Footer;
