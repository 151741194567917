import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import '../css/general-page.css';
import logo from '../images/logo-blue.png'; // Importujte logo
import Footer from '../components/Footer';
import NavBarPublic from '../components/NavBarPublic';

export function RegisterPage() {
  const { currentUser } = useAuth();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const account_typeRef = useRef();
  const account_nameRef = useRef();
  const { signup } = useAuth();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    //const isValid = isPasswordValid && emailRef.current.value.length > 0 && account_typeRef.current.value.length > 0;
    const isValid = isPasswordValid && emailRef.current.value.length > 0;
    setIsFormValid(isValid);
  }, [isPasswordValid]);

  function checkPassword() {
    const isValid = passwordRef.current.value.length >= 6 && passwordRef.current.value === passwordConfirmRef.current.value;
    setIsPasswordValid(isValid);
  }

  async function handleSubmit(e) {
    e.preventDefault();
  
    try {
      setError("");
      setLoading(true);
      await signup(
        emailRef.current.value,
        passwordRef.current.value,
        //account_typeRef.current.value,
        //account_nameRef.current.value
      );
      navigate("/");
    } catch {
      setError("Nepodarilo sa vytvoriť účet");
    }
  
    setLoading(false);
  }


  return (
    <div className="background-none">
      
      {!currentUser ? <NavBarPublic /> : null}


      <div className="login-page">
      <Card style={{ maxWidth: '320px', }} className="login-card">
        <Card.Body className="center" >
        <a href="/">
          <img src={logo} alt="Logo" className="app-logo" />
        </a>
          <h3 className="login-title text-center mb-4">Registrácia</h3>
          <p style={{ width: '100%', }}>K novému účtu automaticky získate 20 uvítacích kreditov pre bezplatné otestovanie aplikácie.</p>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email">
              <Form.Label className="login-form-label">Email</Form.Label>
              <Form.Control className="login-form-input" type="email" ref={emailRef} required />
            </Form.Group>
            

            <Form.Group id="password">
              <Form.Label className="login-form-label">Heslo</Form.Label>
              <Form.Control className="login-form-input" type="password" ref={passwordRef} placeholder="Min 6 znakov" required onChange={checkPassword} />
            </Form.Group>
            <Form.Group id="password-confirm">
              <Form.Label className="login-form-label">Potvrdenie hesla</Form.Label>
              <Form.Control className="login-form-input" type="password" ref={passwordConfirmRef} required onChange={checkPassword} />
            </Form.Group>
            <Button
              className={`login-form-btn${!isFormValid || loading ? ' login-form-btn-disabled' : ''}`}
              type="submit"
              disabled={!isFormValid || loading}
            >
              Registrovať sa
            </Button>
          </Form>
          <div className="login-register">
            Už máte účet? <Link className="login-register-link" to="/login">Prihlásiť sa</Link>
          </div>
        </Card.Body>
      </Card>   
      <Footer /> 
    </div>  </div>

);
}
   
  /*

  <Form.Group id="account_type">
              <Form.Label className="login-form-label">Typ účtu</Form.Label>
              <Form.Control className="login-form-input" as="select" ref={account_typeRef} required>
                <option value="">Vyberte typ účtu</option>
                <option value="Realitný maklér">Realitný maklér</option>
                <option value="Realitná kancelária">Realitná kancelária</option>
                <option value="Asisten RK">Asisten RK</option>
              </Form.Control>
            </Form.Group>

        */

