import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, query, where, getDocs, getDoc } from "firebase/firestore";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { firestore } from '../firebase';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import '../css/share.css';
import logo from '../images/logo-blue.png';
import Footer from '../components/Footer';
import NavBarPublic from '../components/NavBarPublic';
import NavBar from '../components/NavBar';
import { useAuth } from '../contexts/AuthContext';
import { updateDoc, arrayUnion, doc } from "firebase/firestore";

const Share = () => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const folder = searchParams.get('f');
  const sharedProject = searchParams.get('s');

  const [sharedData, setSharedData] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [visibleImages, setVisibleImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);



  useEffect(() => {
    const checkFolderExistence = async () => {
        try {
            const sharingCollectionRef = collection(firestore, "sharing");
            
            let docRef;

            if (sharedProject) {
                docRef = doc(firestore, "sharing", sharedProject);
            } else {
                const q = query(sharingCollectionRef, where("folder", "==", folder));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    docRef = querySnapshot.docs[0].ref;
                }
            }

            if (docRef) {
                const docData = await getDoc(docRef);
                if (docData.exists()) {
                    const data = docData.data();
                    setSharedData(data);

                    // Check if view_counter exists and is a number, otherwise initialize it to 0
                    const currentCounter = typeof data.view_counter === 'number' ? data.view_counter : 0;
          
                    // Update the document with new view log and incremented view counter
                    await updateDoc(docRef, {
                        view_counter: currentCounter + 1,
                        view_logs: arrayUnion(new Date().toISOString())  // Storing timestamp as a string
                    });
                } else {
                    setSharedData(null);
                }
            } else {
                setSharedData(null);
            }
        } catch (error) {
            console.error("Error checking folder existence:", error);
        }
    };

    checkFolderExistence();
}, [folder, sharedProject]);



const loadImages = async () => {
  if (sharedData) {
      const storage = getStorage();
      const folderPath = `${sharedData.root_folder}/${sharedData.owner}/${sharedData.folder}`;
      const folderRef = ref(storage, folderPath);
      const imagesList = await listAll(folderRef);

      // Fetch all image URLs concurrently
      const imagePromises = imagesList.items.map(item => getDownloadURL(item));
      const allImageUrls = await Promise.all(imagePromises);

      const inputImages = allImageUrls.filter(url => !url.includes("thumb_") && url.includes("input_"));
      const outputImages = allImageUrls.filter(url => !url.includes("thumb_") && url.includes("output_"));

      const getNumberFromUrl = url => {
        const match = url.match(/_(\d+)(?=\D|$)/);
        return match ? parseInt(match[1]) : -1;
    };
    

    inputImages.sort((a, b) => {
        const indexA = getNumberFromUrl(a);
        const indexB = getNumberFromUrl(b);
        return indexA - indexB;
    });
    
    outputImages.sort((a, b) => {
        const indexA = getNumberFromUrl(a);
        const indexB = getNumberFromUrl(b);
        return indexA - indexB;
    });

      const sortedImages = [];
      for (let i = 0; i < inputImages.length; i++) {
          sortedImages.push(inputImages[i]);
          const outputImagesForIndex = outputImages.filter(url => url.includes(`output_${i}`));
          sortedImages.push(...outputImagesForIndex);
      }

      setImageUrls(sortedImages);

      // If at least 3 images are loaded, set them as visible
      if (sortedImages.length >= 3) {
          setVisibleImages(sortedImages.slice(0, 3));
          setIsLoading(false);
      } else {
          setVisibleImages(sortedImages);
          setIsLoading(false);
      }
  }
};



  useEffect(() => {
    if (visibleImages.length < imageUrls.length) {
      const timer = setTimeout(() => {
        setVisibleImages([...visibleImages, imageUrls[visibleImages.length]]);
      }, 3);

      return () => clearTimeout(timer);
    }
  }, [visibleImages, imageUrls]);

  useEffect(() => {
    if (sharedData) {
        loadImages();
    }
}, [sharedData]);

  return (
    <div className="share-container">
      {currentUser ? <NavBar /> : <NavBarPublic />}
      {isLoading ? (
        <div className="loading-container center">
          <br /><br />
          <img src={logo} alt="Logo" className="app-logo" />
          <div className="loading-ball"></div>
        </div>
      ) : (
        <div>
          {sharedData ? (
            <div className="slide-container">
              {visibleImages.length > 0 ? (
                <Fade duration={2700} transitionDuration={900}>
                  {visibleImages.map((imageUrl, index) => (
                    <div key={index} className="each-fade">
                      <div className="image-container">
                        <img src={imageUrl} alt={`Image ${index + 1}`} />
                      </div>
                    </div>
                  ))}
                </Fade>
              ) : (
                <p>No images available</p>
              )}
            </div>
          ) : (
            <p>Folder does not exist</p>
          )}
        </div>
      )}

      
      <Footer />
    </div>
  );
};

export default Share;



  /*const loadImages = async () => {
    if (sharedData) {
        const storage = getStorage();
        const folderPath = `${sharedData.root_folder}/${sharedData.owner}/${sharedData.folder}`;
        const folderRef = ref(storage, folderPath);
        const imagesList = await listAll(folderRef);

        // Fetch all image URLs concurrently
        const imagePromises = imagesList.items.map(item => getDownloadURL(item));
        const allImageUrls = await Promise.all(imagePromises);

        const inputImages = allImageUrls.filter(url => !url.includes("thumb_") && url.includes("input_"));
        const outputImages = allImageUrls.filter(url => !url.includes("thumb_") && url.includes("output_"));

        inputImages.sort((a, b) => {
            const indexA = parseInt(a.match(/\d+/)[0]);
            const indexB = parseInt(b.match(/\d+/)[0]);
            return indexA - indexB;
        });

        outputImages.sort((a, b) => {
            const indexA = parseInt(a.match(/\d+/)[0]);
            const indexB = parseInt(b.match(/\d+/)[0]);
            return indexA - indexB;
        });

        const sortedImages = [];
        for (let i = 0; i < inputImages.length; i++) {
            sortedImages.push(inputImages[i]);
            const outputImagesForIndex = outputImages.filter(url => url.includes(`output_${i}`));
            sortedImages.push(...outputImagesForIndex);
        }

        setImageUrls(sortedImages);
        setVisibleImages(sortedImages.slice(0, 2));
        setIsLoading(false);
    }
};*/