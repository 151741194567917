import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import '../css/WelcomeScreen.css'; 
import '../css/bootstrap-grid.css'; 
//import styles from '../css/WelcomeScreen.module.css';
import { Link } from 'react-router-dom';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import logo from '../images/logo-blue.png';
import beforeImage1 from '../images/before-1.jpg';
import afterImage1 from '../images/after-1.jpg';
import beforeImage2 from '../images/before-2.jpg';
import afterImage2 from '../images/after-2.jpg';
import ReactCompareImage from 'react-compare-image';
import Footer from '../components/Footer';
import Iframe from 'react-iframe';
import CookieConsent from 'react-cookie-consent';

import promoVideo from '../images/inolens-promo-v3.mp4';
import promoVideoVTT from '../images/inolens-promo-v3.vtt';
import demoExt from '../images/exterier.webm';
import demoLiving from '../images/obyvacky.webm';
import demoKitchen from '../images/kuchyne.webm';
import demoBedroom from '../images/spalne.webm';
import logoAnimationVideo from '../images/logo.webm';




import playButtonImage from '../images/play.png';

import NavBar from '../components/NavBar';
import NavBarPublic from '../components/NavBarPublic';
import ImageAnimation from '../components/ImageAnimation';

import { useAuth } from '../contexts/AuthContext';
import { Container, Row, Col, Button } from 'react-bootstrap';
//import 'bootstrap/dist/css/bootstrap.min.css';

import { useInView } from 'react-intersection-observer';


import kitchenBefore from '../images/comparison-v2/kuchyna-pred.webp';
import kitchenAfter from '../images/comparison-v2/kuchyna-po.webp';
import livingBefore from '../images/comparison-v2/obyvacka-pred.webp';
import livingAfter from '../images/comparison-v2/obyvacka-po.webp';
import roomBefore from '../images/comparison-v2/izba-pred.webp';
import roomAfter from '../images/comparison-v2/izba-po.webp';
import bedroomBefore from '../images/comparison-v2/spalna-pred.webp';
import bedroomAfter from '../images/comparison-v2/spalna-po.webp';
import exterierBefore from '../images/comparison-v2/exterier-pred.webp';
import exterierAfter from '../images/comparison-v2/exterier-po.webp';
import bathroomBefore from '../images/comparison-v2/kupelna-pred.webp';
import bathroomAfter from '../images/comparison-v2/kupelna-po.webp';
import emptyBefore from '../images/comparison-v2/prazdna-pred.webp';
import emptyAfter from '../images/comparison-v2/prazdna-po.webp';

function ContentBlock({ isReversed, title, text, videoSrc, before, after, index }) {
  const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.25
  });

  

  const animations = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(20px)',
    delay: index * 100  });

  return (
    <animated.div style={animations}>
      <Row ref={ref} className={`mb-4 bootstrap-grid`}>
        {!isReversed && (
          <>
            <Col id="description_l" md={4} className="content-block">
              <h1 id="title">{title}</h1>
              <p>{text}</p>
            </Col>
            <Col md={8} className="content-block">
            <div className="comparisonWrapper_r">
            <ReactCompareImage
                  leftImage={before}
                  rightImage={after}
                  leftImageAlt="Pôvodná fotografia pred úpravou INOLENS"
                  rightImageAlt="Automaticky vygenerovaná inšpirácia na základe pôvodnej fotografie"
                  sliderLineColor="white"
                  sliderLineWidth={2}
                />
            </div>
            </Col>
           
          </>
        )}
        {isReversed && (
          <>
            <Col md={8} className="content-block">
            <div className="comparisonWrapper_l">
            <ReactCompareImage
                  leftImage={before}
                  rightImage={after}
                  leftImageAlt="Pôvodná fotografia pred úpravou INOLENS"
                  rightImageAlt="Automaticky vygenerovaná inšpirácia na základe pôvodnej fotografie"
                  sliderLineColor="white"
                  sliderLineWidth={2}
                />
            </div>
            </Col>
            <Col id="description_r" md={4} className="content-block">
              <h1 id="title">{title}</h1>
              <p>{text}</p>
            </Col>
          
          </>
          
        )}
      </Row>
      <hr />

    </animated.div>
  );
}

function WelcomeScreen() {
  const { currentUser } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const videoRef = useRef(null);

  const [beforeImage, setBeforeImage] = useState(beforeImage1);
  const [afterImage, setAfterImage] = useState(afterImage1);

  const titleStyles = useSpring({
    delay: 200,
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(-20px)' },
    config: { tension: 220, friction: 40 }
  });

  const contentBlocks = [
    {
      title: 'EXTERIÉR',
      text: 'Fotografie exteriérov nehnuteľností často odhaľujú rôzne nedostatky, ktoré môžu odrádzať potenciálnych záujemcov. S aplikáciou INOLENS môžete odstrániť tieto nepríjemné detaily a pritom dokonale reflektovať skutočnú fotografiu. Pohľad na obnovený exteriér motivuje k úvahám o skutočnom potenciáli objektu a prináša svieže inšpirácie aj pre tých, ktorí skrytý potenciál nevidia hneď.',
      videoSrc: demoExt,
      before: exterierBefore,
      after: exterierAfter,

      
    },
    {
      title: 'KUCHYNE',
      text: 'Kuchyňa je jednou z najdôležitejších súčastí domu či bytu. Pre kupujúcich hrá riešenie kuchyne často zásadnú rolu. Starý nábytok a nežiaduce farby môžu skomplikovať jej predaj. INOLENS premení túto miestnosť do takej podoby, akoby mohla vyzerať po renovácii. Pravdivo odhaľuje možnosti rekonštrukcie a zachováva proporcie, čo dáva kupujúcim jasnú predstavu o tom, čo môžu s priestorom urobiť.',
      videoSrc: demoKitchen,
      before: kitchenBefore,
      after: kitchenAfter,

    },
    {
      title: 'OBÝVAČKY',
      text: 'Podobne ako kuchyne, aj obývačka zohráva dôležitú rolu pri rozhodovaní o kúpe nehnuteľnosti. Nie vždy je pre záujemcov jednoduché predstaviť si, ako by mohla vyzerať ich budúca obývačka. Odstránenie drobných nedostatkov, citlivejšie použitie farieb a príjemnejšie osvetlenie priestoru môžu byť presne tým, čo potrebujú záujemcovia na uľahčenie ich rozhodovania.',
      videoSrc: demoLiving,
      before:livingBefore,
      after: livingAfter,

    },
    {
      title: 'SPÁLNE',
      text: 'Aj fotografie spální INOLENS transformuje tak, že starší nábytok získava moderný vzhľad a potláča rušivé farby. Prekrývanie nedostatkov a zachovanie reálnych proporcií miestnosti umožňuje záujemcom vidieť skutočný potenciál a prináša inšpiráciu pre ich budúce plány ako s priestorom naložiť.',
      videoSrc: demoBedroom,
      before: bedroomBefore,
      after: bedroomAfter,

    },

    
    {
      title: 'KÚPEĽNE',
      text: 'Pohľady na kúpeľne INOLENS takisto dokáže zaujímavým spôsobom modernizovať. Aj v tomto prípade aplikácia vytvorí alternatívu v modernejšom duchu a s výberom citlivejšej palety farieb. Upravené fotografie z tejto kategórie ešte v niektorých prípadoch obsahujú nelogické objekty, ale postupne sa bude kvalita aj týchto výstupov zvyšovať.',
      videoSrc: demoBedroom,
      before: bathroomBefore,
      after: bathroomAfter,

    },

    {
      title: 'PRÁZDNE',
      text: 'Aplikácia INOLENS si dokáže poradiť aj s vylepšením prázdnych miestností. V tomto prípade výstupy vo väčšine prípadov získajú krajšie osvetlenie, vynovené podlahy a pekné steny. Občas sa AI snaží v prázdnej miestnosti vymyslieť aj nejaký nábytok, ktorý ale nemusí byť celkom logický. Kvalita spracovania sa v budúcnosti bude zlepšovať.',
      videoSrc: demoBedroom,
      before: emptyBefore,
      after: emptyAfter,

    },
  ];

  const handleToggleImages = () => {
    if (beforeImage === beforeImage1) {
      setBeforeImage(beforeImage2);
      setAfterImage(afterImage2);
    } else {
      setBeforeImage(beforeImage1);
      setAfterImage(afterImage1);
    }
  };

  


  useEffect(() => {
    const handleClickOutside = event => {
      const modal = document.getElementById('myModal');
      if (showModal && modal && !modal.contains(event.target)) {
        setShowModal(false);
      }
    };

    const handleEscKey = event => {
      if (showModal && event.key === 'Escape') {
        setShowModal(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [showModal]);

  useEffect(() => {
    if (showModal) {
      const script = document.createElement('script');
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      script.onload = () => {
        window.Calendly.initInlineWidget({
          url: 'https://calendly.com/info-ml8/inolens-intro/30min?hide_event_type_details=1&hide_gdpr_banner=1',
          parentElement: document.getElementById('calendly-container'),
        });
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [showModal]);

  const handleRedirectLogin = () => {
    window.location.href = '/login';  
 }
  
 const handleRedirectRegister = () => {
  window.location.href = '/register';  
}

  
  return (


    
    <div className="background-none">
      <Helmet>
        
        <meta charset="UTF-8"/>
        <title>INOLENS: AI Nástroj pre realitného makléra</title>
        <meta name="description" content="Pomocou umelej inteligencie upravuje fotografie bytov a domov. Aplikácia je veľmi jednoduchá, úprava fotografií funguje bez nutnosti manuálneho zásahu grafických zručností."/>
        <meta name="keywords" content="realitný maklér, umelej inteligencie, fotografie, nehnuteľnosti, byty, domy, inzeráty, aranžovanie interéru, home staging, 3D vizualizácia"/>

       
        <meta property="og:title" content="INOLENS: AI Nástroj pre Realitného Makléra"/>
        <meta property="og:description" content="Pomocou umelej inteligencie upravuje fotografie bytov a domov. Aplikácia je veľmi jednoduchá, úprava fotografií funguje bez nutnosti manuálneho zásahu a grafických zručností."/>
        <meta property="og:image" content="%PUBLIC_URL%/inolens-app.webp"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://inolens.sk/"/>

        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content="INOLENS: AI Nástroj pre Realitného Makléra"/>
        <meta name="twitter:description" content="Pomocou umelej inteligencie upravuje fotografie bytov a domov. Aplikácia je veľmi jednoduchá, úprava fotografií funguje bez nutnosti manuálneho zásahu a grafických zručností."/>
        <meta name="twitter:image" content="%PUBLIC_URL%/inolens-app.webp"/>
    </Helmet>


      {(currentUser && <NavBar />) || <NavBarPublic />}
      
      <ImageAnimation />
  
      <div className="page" style={{ paddingTop: '45%' }}>
      
        

        
        <div className="welcomePageBlock">
        <h1 id="title" className="center">AI NÁSTROJ PRE REALITNÉHO MAKLÉRA</h1>

        <p className="center">
          Pomocou umelej inteligencie upravuje fotografie bytov a domov. Aplikácia je veľmi jednoduchá, úprava fotografií funguje bez nutnosti manuálneho zásahu. Výsledkom je automaticky vygenerovaná inšpirácia, ktorá odkrýva skrytý potenciál nehnuteľností. Pomáha vzbudiť skutočný záujem aj pri takých bytoch a domoch, kde to na prvý pohľad nie je jednoduché. 
        </p>

        <h2 className="center">Pomáha prezentovať staršie nehnuteľnosti</h2>

          <p className="center">
            Výsledok automatického spracovania dosahuje podobný efekt ako aranžovanie interéru pred fotením (home staging), alebo 3D vizualizácia. Hlavný rozdiel je v tom, že maklér si svoje inzeráty pomocou tejto aplikácie zvládne vylepšiť sám a veľmi lacno. Pre maklérov a realitné kacelárie sme pripravili <Link className="in-text-link" to="/prehlad-nastrojov-pre-maklera">zoznam s porovnaním</Link> aj ďalších možností, ako vylepšiť prezentáciu nehnuteľnosti.
          </p>

          <video className="" ref={videoRef} controls aria-label="Krátke inštruktážne video, ktoré zobrazuje použitie aplikácie INOLENS">
                  <source src={promoVideo} type="video/mp4" />
                  <track src={promoVideoVTT} kind="subtitles" srclang="sk" label="Slovenský" default></track>
          </video>
          <div style={{ display: 'flex', justifyContent: 'center', }}>
                    <button onClick={() => setShowModal(true)} className="login-form-btn round-button">
                        Rezervujte si DEMO
                    </button>
                    <br />
            </div>
        

        </div>

        
 
        <div className="welcomePageBlock">
            <div className="bootstrap-grid">

            <h1 id="title" className="center">POROVNANIE VÝTUPOV S FOTOGRAFIAMI</h1>

            <p className="center">
            Posúvaním deliacej čiary si môžete prezerať rozdiel medzi podlkadovou fotografiou a výstupom, ktorý je automaticky vygenerovaný aplikáciou INOLENS.
          </p>

            <Container>
              {contentBlocks.map((block, index) => (
                <ContentBlock
                  key={index}
                  index={index}
                  isReversed={index % 2 === 1}
                  {...block}
                />
              ))}

            </Container>
            </div>


            
        </div>
        
            <div className="welcomePageBlock">
              
              <h1 id="title" className="center">O službe INOLENS sa môžete dozvedieť viac aj pomocou DEMO ukážky</h1>

              <p className="center">Náš produktový špecialista Vám prostredníctvom videohovoru cca za 20 minút ukáže ako aplikácia funguje. Budete mať tiež priestor sa priamo opýtať na všetko, čo Vás bude o tejto službe zaujímať. Na prezentáciu si tiež nezabudnite pozvať aj svojich kolegov a kolegyne.
              </p>
                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                            <button onClick={() => setShowModal(true)} className="login-form-btn round-button">
                                Rezervujte si DEMO
                            </button>
                            <br />
                    </div>
            </div>

            <div className="welcomePageBlock">
              <h1 id="title" className="center">Službu si môžete otestovať aj zadarmo a hneď</h1>

              <p className="center">Na vyskúšanie si služby pre Vás máme 20 kreditov zadarmo. Stačí ak si v aplikácii vytvoríte bezplatný účet. Nie je potrebná žiadna platobná karta ani iné citlivé údaje. Službu si vyskúšajte hneď založením účtu na tomto odkaze, alebo ešte jednoduchšie prihlásením sa prostredníctvom svojho Google účtu:
              </p>
                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                            <button onClick={handleRedirectRegister} className="login-form-btn round-button">
                                Založiť si účet zdarma
                            </button>

                            <button onClick={handleRedirectLogin} className="login-form-btn round-button">
                                Prihlásiť sa pomocou Google
                            </button>
                            <br />
                    </div>
            </div>

                {showModal && (
                  <div id="myModal" className="modal">
                    <div className="modal-content">
                      <p className="center" style={{ color: 'white', fontSize: '13pt' }}>
                        Vyberte si dátum a čas na živú prezentáciu tejto aplikácie. Ukážeme Vám ako pomocou tohto nástroja jednoducho vytvoríte obsah pre rôzne druhy nehnuteľností. Odhadovaný čas je 10 - 20 minút. Vybrať si tiež môžete medzi videohovorom, alebo klasickým telefonátom.
                      </p>
                      <div id="calendly-container" style={{ width: '100%', height: '720px', position: 'auto' }}></div>
                      <button onClick={() => setShowModal(false)} className="login-form-btn round-button">
                        Zatvoriť
                      </button>
                    </div>
                  </div>
                )}
          
     
  
        <CookieConsent
          location="bottom"
          buttonText="Rozumiem"
          ariaAcceptLabel="Rozumiem"
          cookieName="inolens-basic-cookie"
          style={{ background: '#2B373B' }}
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
          expires={150}
        >
          Táto stránka používa len skutočne nevyhnutné súbory cookies na zabezpečenie správneho poskytovania služby.{' '}
        </CookieConsent>
  
        <Footer />
      </div>
    </div>
  );
  
}

export default WelcomeScreen;
