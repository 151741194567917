import React, { useState, useEffect } from 'react';
import NavBar from '../components/NavBar';
import ImageUploader from '../components/ImageUploader';
import ImageUploaderTeam from '../components/ImageUploaderTeam';
import QueueSizeComponent from '../components/QueueSizeComponent';
import { useAuth } from '../contexts/AuthContext';
import Footer from '../components/Footer';
import logo from '../images/logo-blue.png'; // Importujte logo
import { firestore } from "../firebase";

const DemoAppPage = () => {
  const { currentUser } = useAuth();
  const [receivedInvitations, setReceivedInvitations] = useState([]);
  const [hasActiveInvitation, setHasActiveInvitation] = useState(false);
  const [teamName, setTeamName] = useState([]);
  const [invitationId, setInvitationId] = useState(null);
  const [parentUid, setParentUid] = useState(null);
  const [isTeamUploader, setIsTeamUploader] = useState(false);

  useEffect(() => {
    const fetchParentAccountCredit = async () => {
      if (currentUser) {
        const userSnapshot = await firestore.collection("users").doc(currentUser.uid).get();
        const userData = userSnapshot.data();

        if (userData.received_invitations) {
          const invitationsPromises = userData.received_invitations.map(async (invitationId) => {
            const invitationSnapshot = await firestore.collection("team_invitations").doc(invitationId).get();
            return invitationSnapshot.data();
          });

          const invitations = await Promise.all(invitationsPromises);
          setReceivedInvitations(invitations);

          const activeInvitation = invitations.find((invitation) => invitation.status === "active");

          if (activeInvitation) {
            setInvitationId(activeInvitation.invitation_id);
            setTeamName(activeInvitation.team_name);
            setParentUid(activeInvitation.uid_parent);
            setHasActiveInvitation(true);
          }
        }
      }
    };

    fetchParentAccountCredit();
  }, [currentUser]);

  return (
    <div className="background-none">
      <NavBar />
      <div className="login-page">
        <div className="app-card">
          <img src={logo} alt="Logo" className="app-logo" />
          
          {hasActiveInvitation && (
            <>

              <div style={{ textAlign: 'center', padding: '0.75rem' }}>
                <label style={{padding: '0.5rem' }}>
                  <input
                    type="radio"
                    value="individual"
                    checked={!isTeamUploader}
                    onChange={() => setIsTeamUploader(false)}
                  />
                  Vlastný kredit
                </label>
                <label style={{padding: '0.5rem' }}>
                  <input
                    type="radio"
                    value="team"
                    checked={isTeamUploader}
                    onChange={() => setIsTeamUploader(true)}
                  />
                  Tímový kredit
                </label>
              </div>
              <hr />

            </>
         )}
          
          {isTeamUploader ? (
            <ImageUploaderTeam invitationId={invitationId} parentUid={parentUid} />
          ) : (
            <ImageUploader currentUser={currentUser} />
          )}
          
          <QueueSizeComponent />
        </div>
      </div>
      <div>
       <br /><br />
      </div>
      <Footer />
    </div>
  );
};

export default DemoAppPage;

