
import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useInvitations } from '../contexts/InvitationsContext';

const InvitationsStatus = ({ userId }) => {
  const [invitationsData, setInvitationsData] = useState({ usedInvitations: 0, maxInvitations: 0 });
  const { invitationsChanged, setUsedInvitations, setMaxInvitations,} = useInvitations();

  useEffect(() => {
    const fetchUserData = async () => {
      const db = getFirestore();
      const userDocRef = doc(db, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        const accountMembersLength = userData.account_members ? userData.account_members.length : 0;
        setUsedInvitations(accountMembersLength);
        setMaxInvitations(userData.account_team_size);
        setInvitationsData({ usedInvitations: accountMembersLength, maxInvitations: userData.account_team_size });
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId, invitationsChanged, setUsedInvitations, setMaxInvitations]);

  const { usedInvitations, maxInvitations } = invitationsData;
  const percentage = Math.round((usedInvitations / maxInvitations) * 100);
  const overPercentage = percentage > 100 ? percentage - 100 : 0;

  if (maxInvitations === 0) {
    return null;
  }

  return (
    <div>
      <p>
        Počet použitých pozvánok je {usedInvitations}/{maxInvitations} ({percentage}%)
      </p>
      <progress max="100" value={percentage - overPercentage}></progress>
      {overPercentage > 0 && (
        <>
          <span>+{overPercentage}%</span>
          <progress max="100" value={overPercentage}></progress>
        </>
      )}
    </div>
  );
};

export default InvitationsStatus;


