import React, { useEffect, useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { loadStripe } from "@stripe/stripe-js";

function StripeCustomerPortal({ userId }) {
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAuth();
  //const db = firebase.firestore();
  const [stripeId, setStripeId] = useState(null);


  useEffect(() => {
    const fetchStripeId = async () => {
      const db = getFirestore();
      const userRef = doc(db, 'users', currentUser.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        setStripeId(userSnap.data().stripeId);
      }
    };
    
    if (currentUser) {
      fetchStripeId();
    }
  }, [currentUser]);
  

  const handleAccessPortal = async () => {
    setIsLoading(true);
  
    try {
      const response = await fetch("https://us-central1-react-002-6aaf0.cloudfunctions.net/createPortalLink", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customer: stripeId,
          return_url: window.location.origin,
        }),
      });
  
      const result = await response.json();
      if (result && result.url) {
        window.location.assign(result.url);
      } else {
        alert('Chyba pri prístupe k Customer Portalu.');
      }
    } catch (error) {
      console.error(error);
      alert('Chyba pri prístupe k Customer Portalu.');
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div>

      <button className="" onClick={handleAccessPortal} disabled={isLoading}>
        {isLoading ? 'Loading...' : 'Spravovať predplatné'}
      </button>
    </div>
  );
}

export default StripeCustomerPortal;

/*
      <div>User ID: {currentUser ? currentUser.uid : 'Not logged in'}</div>
      <div>Stripe ID: {stripeId ? stripeId : 'Not available'}</div>
      */
