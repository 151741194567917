import React, { useState, useEffect, useRef } from 'react';
import '../css/WelcomeScreen.css'; 
import '../css/bootstrap-grid.css'; 
//import styles from '../css/WelcomeScreen.module.css';
import { Link } from 'react-router-dom';
import { useSpring } from '@react-spring/core';
import { animated } from '@react-spring/web';
import logo from '../images/logo-blue.png';
import review_title from '../images/review-title.jpg';

import Footer from '../components/Footer';
import Iframe from 'react-iframe';
import CookieConsent from 'react-cookie-consent';




import NavBar from '../components/NavBar';
import NavBarPublic from '../components/NavBarPublic';
import { useAuth } from '../contexts/AuthContext';
import { Container, Row, Col, Button } from 'react-bootstrap';
//import 'bootstrap/dist/css/bootstrap.min.css';

import { useInView } from 'react-intersection-observer';




function ContentBlock({ isReversed, title, text1, text2, price, duration, pros, cons, videoSrc, before, after, index }) {
  const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.25
  });

  const animations = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(20px)',
    delay: index * 100  });

  return (
    <animated.div style={animations}>
      <Row ref={ref} className={`mb-4 bootstrap-grid`}>
        { (
          <>
            <Col id="description_l" md={4} className="content-block">
              
              <h1 >{title}</h1>
              <hr />
              <p id="review">trvanie dodania: {duration}</p>
              <p id="review">cena: {price}</p>
              <p id="review_pros">+ {pros}</p>
              <p id="review_cons">- {cons}</p>

            </Col>
            <Col md={8} className="content-block">
            <div id="review_text">
            <p>{text1}</p>
            <p>{text2}</p>
            </div>
            </Col>
           
          </>
        )}
        
      </Row>
      <hr />
    </animated.div>
  );
}

function ReviewScreen() {
  const { currentUser } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const videoRef = useRef(null);

  const animations = useSpring({
      });



  const titleStyles = useSpring({
    delay: 200,
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(-20px)' },
    config: { tension: 220, friction: 70 }
  });

  const contentBlocks = [
    {
      title: 'PROFESIONÁLNA REALITNÁ FOTOGRAFIA',
      text1: 'Úplne najprirodzenejší spôsob ako profesionálne prezentovať nehnuteľnosť je kvalitná fotografia. Napriek tomu, ako sa v dnešnej dobe užívateľsky sprístupnila kvalita digitálnej fotografie, zďaleka sa nejedná o štandard. Ešte stále je možné kvalitu inzerátu jednoznačne odlíšiť od „lenivej“ konkurencie fotografiou. Hoci len trochu aranžovaná fotka moderným mobilom, alebo cenovo dostupným fotoaparátom dokáže prekonať priemernú kvalitu v inzerátoch.',
      text2: 'Citeľne je potom možné dobre pripravenú prezentáciu odlíšiť prizvaním profesionálneho fotografa. Ten pomocou kvalitných objektívov, dodatočného osvetlenia, statívov a pokročilej softvérovej postprodukcie dodá zábery vysokej kvality. Z inzerátu vďaka profesionálnym fotografiám má dobrý dojem aj predávajúci klient, docení ich kupujúci a samotný maklér sa tiež cíti ako skutočný profesionál. Celému procesu realitného obchodu to len prospeje.',
      duration: '0 - 7 dní',
      price: '0 - 200€',
      pros: 'zrozumiteľnosť, dostupnosť, jednoduchosť',
      cons: 'vo veľkom počte záberov môže byť ťažké sa orientovať',
    },

    {
        title: 'VIDEO PRODUKCIA',
        text1: 'Nadstavbou nad fotografiou je úplne prirodzene video. Maklérom komentovaná videoprehliadka je z pohľadu kupujúceho klienta mimoriadne ceneným obsahom. Okrem toho, že získajú kvalitnú prehliadku nehnuteľnosťou, spoznávajú aj svojho makléra.',
        text2: 'Tým, že maklér ide s „kožou na trh“ zásadne vzrastá dôvera v prezentované informácie. Toto vyžaduje okrem technických prostriedkov hlavne osobnosť makléra, ktorá zvláda tlak kamery. ',
        duration: '1 - 2 týždne',
        price: '150 - 300€',
        pros: 'zrozumiteľnosť, vysoký nadštandard a exkluzívnosť prezentácie',
        cons: 'nie je to pre každého makléra prirodzené',
    },

    {
        title: '360° VR PREHLIADKY',
        text1: 'Relatívne dobre etablovanou možnosťou ako zvýšiť informačnú hodnotu inzerátov je tvorba VR prehliadok službou Matterport, prípadne alternatívami ako je Kuula, Istaging, Momento360, Revio, Vieweet, Giraffe360, Mollyinvr, Cupix, Feelestate, Insta360, Eyespy360, Lapentor, Cloudpano, Theasys, Grandpano a podobne.',
        text2: 'Tieto virtuálne prehliadky sprostredkúvajú absolútne najvernejšiu podobu priestorov prezentovanej nehnuteľnosti. Z pohľadu kupujúceho sa vždy bude jednať o silné pozitívum v procese výberu domu či bytu. Z pohľadu predávajúceho sa občas maklér môže stretnúť s určitou opatrnosťou. Tieto panoramatické 360° zábery odhaľujú o nehnuteľnosti a jej zariadení naozaj všetko. Otázka citlivosti na vlastné súkromie konkrétneho predávajúceho môže zohrávať určitú rolu.',
        duration: 'cca 1 týždeň',
        price: '100 - 300€',
        pros: 'zrozumiteľnosť, výborné pokrytie priestorov, interaktivita',
        cons: 'odkrýva aj citlivé časti súkromia',
    },

    {
        title: '3D VIZUALIZÁCIE',
        text1: 'V prípade, kedy sa jedná o nehnuteľnosť pred rekonštrukciou, vo výstavbe, alebo dokonca len „na papieri“ je 3D vizualizácia nezastupiteľným nástrojom na jej prezentáciu. Pomocou 3D vizualizácie je možné veľmi detailne znázorniť budúci vzhľad interiéru a exteriéru. Digitálna produkcia prináša veľkú mieru slobody ako sprostredkovať budúci vzhľad priestorov. V súčasnosti sú bežné okrem statických obrázkov (renderov) aj 360° panoramatikcé prehliadky a animácie.',
        text2: '3D vizualizácia je oporným prvkom v procese predaja developerských projektov, kedy za relatívne malú investovanú sumu, majú možnosť prezentovať svoj investičný záujem. Vďaka tomu predajcovia často získavajú reálnych záujemcov a s nimi tiež finančné zdroje na ďalšie pocesy výstaby.',
        duration: 'cca 1 - 4 týždne',
        price: '150 - 600€',
        pros: 'prezentácia ešte neexistujúcich priestorov a plánovaných rekonštrukcií',
        cons: 'časovo náročné na spracovanie',
    },

    {
        title: '2D A 3D PÔDORYSY',
        text1: 'S výnimkou pozemkov je pre každú nehnuteľnosť absolútne zásadná jej vnútorná dispozícii. Túto informáciu dokážu sprostredkovať 2D a 3D pôdorysy. Oproti štandardným výkresom z projektovej dokumentácie obsahujú menej technický detailov a sú vďaka tomu lepšie čitateľné laikom. Na vyhotovenie pôdorysu je potrebné nehnuteľnosť zamerať, prípadne získať stavebnú dokumentáciu.',
        text2: 'Na samotné prekreslenie sú vhodné nástroje ako napríklad Floorplanner, FloorPad, SweetHome 3D, HomeStyler a podobne. Prekreslenie 3D pôdorysu je v silách aj samotného makléra, prípadne je možné sa obrátiť na cenovo dostupnú službu prekreslenia pôdorysu grafikom. Napriek tomu, že 3D pôdorys je mimoriadne dostupným a obsahovo priam až nevyhnutným prvkom, v inzerátoch nie je samozrejmosťou. Toto je jednoduchý spôsob ako sa aj v tejto dobe odlíšiť pred konkurenciou.',
        duration: 'do 2 dní',
        price: '0 - 30€',
        pros: 'cenová dostupnosť, rýchlosť dodania, obrovská informačná hodnota',
        cons: 'možná potreba nehnuteľnosť zamerať',
    },

    {
        title: 'ZÁBERY Z DRONU',
        text1: 'Pre kupujúceho je kontext nehnuteľnosti veľmi zásadná informácia, ktorú sa makléri snažia sprostredkovať v popise inzerátu a fotografiami. S cenovou dostupnosťou dronov tak maklérom pribudol ohromný nástroj ako nehnuteľnosti prezentovať doslova z inej perspektívy. Dronové zábery na rodinné domy, pozemky či priemyselné objekty sú vysoko cenenou súčasťou inzerátov. ',
        text2: 'Na druhej strane je tu často otázka súkromia susedných nehnuteľností. Zásadnú rolu pri fotení dronom zohráva tiež aktuálne ročné obdobie a počasie. Určite to chce pri hľadaní vhodného termínu snímania aj trochu šťastia. Netreba tiež zabúdať že lietanie dronom je regulované legislatívou.',
        duration: 'cca 1 - 4 týždne',
        price: '150€ - 300€',
        pros: 'obrovská informačná hodnota',
        cons: 'faktor počasia, otázky súkromia susedov',
    },

    {
        title: 'VIRTUÁLNY DRON',
        text1: 'Ako alternatíva k fotografovaniu dronom je jeho digitálna verzia, ktorá sa opiera o 3D satelitné dáta.  Animovaním virtuálnej kamery v prostredí 3D Google máp je možné získať zábery veľmi podobné tým z dronu. Kompromisná je len kvalita z bezprostrednej vzdialenosti od nehnuteľnosti. Pokiaľ sa ale jedná o širší kontext nehnuteľnosti, tak Virtuálny dron dokáže sprostredkovať napríklad občiansku a technickú vybavenosť lokality, rekreačné možnosti a dopravnú napojenosť.',
        text2: 'Pre kupujúcich tieto informácie môžu hrať rovnako zásadnú rolu akou je vnútorná dispozícia priestorov. V tomto prípade počasie nehrá žiadnu rolu a rovnako nedochádza k narušovaniu súkromia v susedstve nehnuteľnosti. ',
        duration: '3 - 5 dní',
        price: '199€',
        pros: 'obrovská informačná hodnota, bez narušenia súkromia, záber na široký kontext',
        cons: 'nízka kvalita z bezprostrednej blízkosti objektu',
    },

    {
        title: 'HOME STAGING',
        text1: 'Názov tejto služby je možné zmysluplne preložiť ako „vybavenie domácnosti“, alebo „zariadenie nábytkom“. Služba spočíva v tom, že pred samotným fotením, sa daná nehnuteľnosť naaranžuje reálnym nábytkom či doplnkami. Zobraziť nehnuteľnosť ako útulnú a sympaticky vyzdobenú má na kupujúcich veľmi pozitívny vplyv.',
        text2: 'Nevýhodou býva, že nábytok ani doplnky po fotení v nehnuteľnosti nezostanú a prehliadky sa potom s kupujúcim absolvujú v pôvodnom stave. Je tiež pomerne náročné fyzicky doviezť nábytok a manipulovať ním. ',
        duration: '1 - 2 týždne',
        price: '150 - 400€',
        pros: 'útulnejšie fotografie, napomáha predstavivosti',
        cons: 'fyzická manipulácia nábytkom',
    },

    {
        title: 'VIRTUAL HOME STAGING',
        text1: 'Podobne ako Virutálny dron prekonáva určité limity fyzického lietania dronom, aj Virtual home staging prekonáva jeho fyzickú verziu v mnohých obmedzeniach. Jedná sa o špeciálny druh 3D vizualizácie, kedy sa kombinuje reálny záber prázdnej, alebo zastaralo zariadenej miestnosti a pomocou 3D grafických nástrojov sa tento priestor vyplní moderným a estetickým zariadením.',
        text2: 'Maklér potrebuje klasické fotografie a potom už len zadefinovať svoju predstavu o prevedení virtuálneho zariadenia. Variabilita štýlov, materiálov a nábytkov je v 3D riešení veľmi pestrá. Táto služba sa dá realizovať aj dlho po tom, čo sa nehnuteľnosť predáva v pôvodnom stave. ',
        duration: '1 - 2 týždňe',
        price: '25 - 150€',
        pros: 'podpora predstavivosti, variability, vernosť napojenia 3D modelov na fotografiu',
        cons: 'možná časová náročnosť',
    },

    {
        title: 'INOLENS',
        text1: 'Automatické spracovanie fotografií pomocou umelej inteligencie sa dá najviac prirovnať k virtuálnemu home stagingu. Aj táto služba prináša alternatívne zariadenie priestoru na základe fotografie pôvodného stavu. Pomáha znázorniť priestor v modernom a estetickom duchu. Potláča rôzne nedostatky, ktoré väčšina starších nehnuteľností má. Odkrýva tak hodnotný potenciál aj v prípade starších nehnuteľností.',
        text2: 'Výhodou sú minimálne nároky na obsluhu zo strany používateľa. Nevyžadujú sa žiadne grafické zručnosti a maklér si tak poradí úplne sám. Ďalšou výhodou je rýchlosť, vytvorenie nových verzií zaberie len minúty a cena za jedenú spracovanú fotografiu je len v rádoch centov. Nízke nároky na obsluhu sú zároveň určitým limitom, nakoľko je konkrétna podoba výstupu daná značnou mierou náhody.',
        duration: 'niekoľko minút',
        price: '0,1 - 2€ € ',
        pros: 'podpora predstavivosti, vernosť napojenia na fotografiu, podnecuje inšpiráciu',
        cons: 'faktor náhody ako zdroj variability',
    },
  ];

  useEffect(() => {
    if (showModal) {
      const script = document.createElement('script');
      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
      script.onload = () => {
        window.Calendly.initInlineWidget({
          url: 'https://calendly.com/info-ml8/inolens-intro/30min?hide_event_type_details=1&hide_gdpr_banner=1',
          parentElement: document.getElementById('calendly-container'),
        });
      };
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [showModal]);

  const handleRedirectHome = () => {
    window.location.href = '/';  
 }

 useEffect(() => {
    const handleClickOutside = event => {
      const modal = document.getElementById('myModal');
      if (showModal && modal && !modal.contains(event.target)) {
        setShowModal(false);
      }
    };

    const handleEscKey = event => {
      if (showModal && event.key === 'Escape') {
        setShowModal(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    document.addEventListener('keydown', handleEscKey);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [showModal]);
  

  
  return (
    <div className="background-none">
      {(currentUser && <NavBar />) || <NavBarPublic />}
  
      <div className="page">
        <div className="welcomePageBlock">
          
          
        <h1 id="title" className="center">VEĽKÝ PREHĽAD NÁSTROJOV PRE REALITNÉHO MAKLÉRA</h1>

        <h2 className="center">Fotografie, video, VR prehliadky, vizualizácie, pôdorysy, dron, virtálny dron, home staging, virtual home staging a AI úprava fotografií</h2>
        
        <Container className="bootstrap-grid">
        <Row className={`mb-4`}>
        { (
          <>
            <Col id="description_l" md={4} className="content-block">
              
            <img src={review_title} style={{ borderRadius: '12px', justifyContent: 'center', }} alt="moderný realitný maklér používa digitálne nástroje" className="app-logo" />
             

            </Col>

            <Col md={8} className="content-block">
            <div id="review_text">

                <p className="center">V tomto článku sa pokúsime urobiť prehľad možností, ktoré má realitný maklér k dispozícii a trochu ich aj vzájomne porovnáme. Spomenuté nástroje slúžia na to, aby sa dosiahli čo najlepšie predajné výsledky a uspokojili potreby klienta na strane kupujúcich, ale aj predávajúcich. </p>
                <p className="center"> Všetky z uvedených možností prinášajú záujemcom určitú vizuálnu informáciu o nehnuteľnosti, ale aj o profesionalite predávajúceho makléra.</p>
                <p className="center">Ku každej z možností ako podporiť predaj nehnuteľnosti sme uviedli jej bežnú cenovú a časovú dostpunosť. Jedná sa o orientačné informácie vzťahované na bežné rezidenčné nehnuteľnosti - byty a domy.</p>

            </div>
            </Col>
           
          </>
        )}
        
      </Row>
      </Container>
   

        
        </div>



        <div className="welcomePageBlock">    

            <div className="bootstrap-grid">

            <Container>
              {contentBlocks.map((block, index) => (
                <ContentBlock
                  key={index}
                  index={index}
                  isReversed={index % 2 === 1}
                  {...block}
                />
              ))}

            </Container>
            </div>
               
          
        </div>

        <div className="welcomePageBlock">
            <h1 id="title" className="center">O službe INOLENS sa môžete dozvedieť viac aj pomocou DEMO ukážky</h1>
            
            

            <p className="center">Náš produktový špecialista Vám prostredníctvom videohovoru cca za 20 minút ukáže ako aplikaci funguje a budete mať priestro sa priamo opýtať na všetko čo Vás bude o tejto službe zaujímať.
            </p>
            
            

            <div style={{ display: 'flex', justifyContent: 'center', }}>
                    <button id="button" onClick={handleRedirectHome} className="login-form-btn">
                        O službe INOLENS
                    </button>

                    <button id="button" onClick={() => setShowModal(true)} className="login-form-btn">
                        Rezervujte si DEMO
                    </button>
                    <br />
            </div>


        </div>

          
  
        <CookieConsent
          location="bottom"
          buttonText="Rozumiem"
          cookieName="myAwesomeCookieName2"
          style={{ background: '#2B373B' }}
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
          expires={150}
        >
          Táto stránka používa len skutočne nevyhnutné súbory cookies na zabezpečenie správneho poskytovania služby.{' '}
        </CookieConsent>

        {showModal && (
                  <div id="myModal" className="modal">
                    <div className="modal-content">
                      <p className="center" style={{ color: 'white', fontSize: '13pt' }}>
                        Vyberte si dátum a čas na živú prezentáciu tejto aplikácie. Ukážeme Vám ako pomocou tohto nástroja jednoducho vytvoríte obsah pre rôzne druhy nehnuteľností. Odhadovaný čas je 10 - 20 minút. Vybrať si tiež môžete medzi videohovorom, alebo klasickým telefonátom.
                      </p>
                      <div id="calendly-container" style={{ width: '100%', height: '720px', position: 'auto' }}></div>
                      <button onClick={() => setShowModal(false)} className="login-form-btn">
                        Zatvoriť
                      </button>
                    </div>
                  </div>
                )}
  
        <Footer />
      </div>
    </div>
  );
  
}

export default ReviewScreen;


/*
<div className="welcomePageBlock">
<h1 id="title" className="center">Pomáha prezentovať staršie nehnuteľnosti</h1>

<p className="center">
  Ide o službu, ktorá dosahuje podobný efekt ako aranžovanie interéru pred fotením (virtual home staging), alebo 3D vizualizácia a maklér si takto svoje inzeráty zvládne vylepšiť sám. 
</p>


</div>*/

/*

<div className="comparisonWrapper">
            <ReactCompareImage
              leftImage={beforeImage}
              rightImage={afterImage}
              leftImageAlt="Pred"
              rightImageAlt="Po"
              sliderLineColor="white"
              sliderLineWidth={2}
            />
            </div>*/


            /*<div className="comparisonWrapper">
              <ReactCompareImage 
                leftImage={beforeImage}
                rightImage={afterImage}
                leftImageAlt="Pred"
                rightImageAlt="Po"
                sliderLineColor="white"
                sliderLineWidth={2}
              />
            </div>
            
            
            <button className="login-form-btn" onClick={handleToggleImages}>Prepnúť: interiér/exteriér</button>
            
            */

/*
            <video className="" ref={videoRef} controls>
                  <source src={promoVideo} type="video/mp4" />
                </video>*/