import React, { useState, useEffect } from "react";
import { firestore } from '../firebase'; // Importujte konfiguráciu Firestore a arrayUnion
import { arrayUnion } from 'firebase/firestore'; // Importujte arrayUnion z balíčka firebase/firestore

import { useAuth } from '../contexts/AuthContext';
import { useInvitations } from '../contexts/InvitationsContext';
import "../css/invitations-form.css";
import "../css/general-page.css";
import "firebase/functions";

import { getFunctions } from 'firebase/functions';
import { httpsCallable } from 'firebase/functions';
const functions = getFunctions();

const sendInvitationEmail = httpsCallable(functions, 'sendInvitationEmail'); // Create a callable function

async function sendInvitation(sender, email, invitation_id) {
    
  
    try {
      const result = await sendInvitationEmail({ sender, email, invitation_id });
  
      if (result.data.success) {
        console.log("Email s pozvánkou bol úspešne odoslaný");
      } else {
        console.error("Chyba pri odosielaní emailu s pozvánkou:", result.data.error);
      }
    } catch (error) {
      console.error("Chyba pri volaní Cloud Function:", error);
    }
  }
  


const InviteForm = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { currentUser } = useAuth();
  const uid = currentUser ? currentUser.uid : null;
  const sender = currentUser ? currentUser.email : null;
  const { setInvitationsChanged, usedInvitations, maxInvitations } = useInvitations();

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (message) {
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 5000);

      setTimeout(() => {
        setMessage("");
      }, 5300);
    }
  }, [message]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!email) {
      setMessage("Prosím, zadajte e-mailovú adresu.");
      return;
    }

      try {
        // Načítanie zoznamu e-mailových adries členov tímu
        const userDocRef = firestore.doc(`users/${uid}`);
        const userDocSnapshot = await userDocRef.get();
        const accountMembers = userDocSnapshot.get("account_members") || [];
    
        // Kontrola, či zadaná e-mailová adresa už je v zozname
        if (accountMembers.includes(email)) {
          setMessage("Zadaný užívateľ už je členom tímu.");
          return;
        }
    
        
        // Uloženie pozvánky do Firestore
        const invitationRef = await firestore.collection("team_invitations").add({
          invitation_date: new Date(),
          email: email,
          status: "pending",
          credit_spent_this_month: 0,
          credit_spent_total: 0,
          invitation_id: "this document id",
          uid_parent: uid,
          sender: sender  
        });

        // Získanie ID práve vytvoreného dokumentu
        const invitation_id = invitationRef.id;

        // Aktualizovanie dokumentu s novým invitation_id
        await firestore.collection("team_invitations").doc(invitation_id).update({
        invitation_id: invitation_id
        });
            
        // Pridanie e-mailu pozvaného používateľa do poľa account_members
        await userDocRef.update({
          account_members: arrayUnion(email),
          invitations_ids: arrayUnion(invitation_id),
        });

        await sendInvitation(sender, email, invitation_id);
  
      setMessage(`Pozvánka úspešne odoslaná na ${email}.`);
      setInvitationsChanged((prev) => !prev); // Zmeňte hodnotu na opak predchádzajúcej hodnoty
  
      setEmail("");
    } catch (error) {
      console.error("Chyba pri odosielaní pozvánky: ", error);
      setMessage("Chyba pri odosielaní pozvánky. Skúste to znova.");
    }
  };
  


  if (maxInvitations === 0) {
    return null;
  }

  return (
    <div>
      <h2>Vytvorenie pozvánky</h2>
      {usedInvitations < maxInvitations ? (
        <form onSubmit={handleSubmit}>
          <label  htmlFor="email">E-mail:</label>
          <input className="input-field"
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button className="input-button" type="submit">Odoslať pozvánku</button>
        </form>
      ) : (
        <p>Bol dosiahnutý limit veľkosti tímu</p>
      )}
      <div className="message-wrapper">
        {message && (
          <p className={showMessage ? "" : "fade-out"}>{message}</p>
        )}
      </div>
      <hr></hr> 
    </div>

    
  );
};

export default InviteForm;