
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import '../css/nav-bar.css';
import logo from '../images/logo-navbar.png';

function NavBarPublic() {
  //const { currentUser, logout, credit, blockedCredit, account_type, account_name, isSubUser } = useAuth();

  //const navigate = useNavigate();
  const location = useLocation();

  
  

  return (
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" className="nav-bar">
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto navbar-left">
        
          <Nav.Item> <Link to="/" className={location.pathname === '/' ? 'nav-link nav-link-active navbar-link' : 'nav-link navbar-link'}><img src={logo} alt="Logo" className="nav-bar-logo"/></Link></Nav.Item>  
        </Nav>
        
        <Nav className="ml-auto navbar-right navbar-account-wrapper">
        <Nav.Item> <Link to="/cennik" className={location.pathname === '/cennik' ? 'nav-link nav-link-active navbar-link' : 'nav-link navbar-link'}>Cenník</Link></Nav.Item>  
          <Nav.Item> <Link to="/register" className={location.pathname === '/register' ? 'nav-link nav-link-active navbar-link' : 'nav-link navbar-link'}>Registrácia</Link></Nav.Item>  
          <Nav.Item> <Link to="/login" className={location.pathname === '/login' ? 'nav-link nav-link-active navbar-link' : 'nav-link navbar-link'}>Prihlásenie</Link></Nav.Item>  
          
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
  
}

export default NavBarPublic;



