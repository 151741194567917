import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import firebase from '../firebase';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import logo from '../images/logo-blue.png'

function InvoicesList() {
    const { currentUser } = useAuth();
    const [invoices, setInvoices] = useState([]);

    const [selectedInvoice, setSelectedInvoice] = useState(null);


    const db = firebase.firestore();
    const firestore = getFirestore();

    const [loading, setLoading] = useState(true);




    useEffect(() => {
    const fetchUserData = async () => {
      const userDocRef = doc(firestore, 'users', currentUser.uid);
      //const userDoc = await getDoc(userDocRef);

      setLoading(false);
    };

    fetchUserData();
  }, [currentUser.uid, firestore]);

    useEffect(() => {
        if (currentUser) {
            const fetchData = async () => {
                const invoicesRef = db.collection("users").doc(currentUser.uid).collection("custom_invoices");

                const invoicesList = [];

                const invoicesSnap = await invoicesRef.get();
                for (const invoice of invoicesSnap.docs) {
                    //const invoicesSnap = await invoice.ref.collection("invoices").get();

                        const invoiceData = invoice.data();
                        invoicesList.push({
                            
                            datum_vystavenia: invoiceData.datum_vystavenia,
                            stripe_referencia: invoiceData.stripe_referencia,
                            pdf_url: invoiceData.pdfUrl,

                        });
                }

                setInvoices(invoicesList);
            };

            fetchData();
        }
    }, [currentUser]);


    return (
        <div>
            {invoices.map((invoice, index) => {

                const datum_vystavenia = new Date(invoice.datum_vystavenia*1000).toLocaleDateString('sk-SK');

                return (
                    <div key={index}>
                        
                        <a href={invoice.pdf_url} download>
                        {datum_vystavenia} {invoice.stripe_referencia}
                        </a>

                    </div>
                );
            })}

        </div>
    );
}

export default InvoicesList;