import { createContext, useContext, useState } from 'react';

const InvitationsContext = createContext();

export function useInvitations() {
  return useContext(InvitationsContext);
}

export function InvitationsProvider({ children }) {
  const [invitationsChanged, setInvitationsChanged] = useState(false);
  const [usedInvitations, setUsedInvitations] = useState(0);
  const [maxInvitations, setMaxInvitations] = useState(0);

  const value = {
    invitationsChanged,
    setInvitationsChanged,
    usedInvitations,
    setUsedInvitations,
    maxInvitations,
    setMaxInvitations,
  };

  return (
    <InvitationsContext.Provider value={value}>
      {children}
    </InvitationsContext.Provider>
  );
}
