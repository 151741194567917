import React, { useState } from "react";
import { firestore } from "../firebase";
import { arrayUnion } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
//import "../css/join-team-form.css";

const JoinTeamForm = () => {
  const [invitationId, setInvitationId] = useState("");
  const [message, setMessage] = useState("");
  const { currentUser } = useAuth();
  const uid = currentUser ? currentUser.uid : null;
  const userEmail = currentUser ? currentUser.email : null;
  const [parentUid, setParentUid] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!invitationId) {
      setMessage("Prosím, zadajte ID pozvánky.");
      return;
    }

    try {
      const invitationRef = firestore.collection("team_invitations").doc(invitationId);
      const currentUserRef = firestore.collection("users").doc(currentUser.uid);
      
      const invitationSnapshot = await invitationRef.get();
      

      if (!invitationSnapshot.exists) {
        setMessage("Pozvánka s týmto ID neexistuje.");
        return;
      }

      const invitationData = invitationSnapshot.data();
      const parentUserRef = firestore.collection("users").doc(invitationData.uid_parent);
      setParentUid(invitationData.uid_parent);
      console.log("invitationData.uid_parent:", invitationData.uid_parent);

      if (invitationData.email !== userEmail) {
        setMessage("Táto pozvánka nie je určená pre váš e-mail.");
        return;
      }

      const userDocRef = firestore.doc(`users/${uid}`);
      await userDocRef.update({
        received_invitations: arrayUnion(invitationId),
      });

      // Zmena stavu pozvánky na "active"
      await invitationRef.update({
        status: "active",
        uid_member: uid,
      });

      await currentUserRef.update({
        active_invitations: arrayUnion(invitationId),
      });

      
      await parentUserRef.update({
        active_invitations: arrayUnion(invitationId),
      });
      


      setMessage("Úspešne ste sa pripojili k tímu.");
    } catch (error) {
      console.error("Chyba pri pripájaní sa k tímu:", error);
      setMessage("Chyba pri pripájaní sa k tímu. Skúste to znova.");
    }
};


  return (
    <div>
      <h2>Pripojenie sa k tímu</h2>
      <form onSubmit={handleSubmit}>
        <label htmlFor="invitationId">ID pozvánky:</label>
        <input
          className="input-field"
          type="text"
          id="invitationId"
          name="invitationId"
          value={invitationId}
          onChange={(e) => setInvitationId(e.target.value)}
          required
        />
        <button className="input-button" type="submit">Pripojiť sa</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default JoinTeamForm;
