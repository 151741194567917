import React, { useEffect, useState, useRef } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { firestore } from '../firebase';
import '../css/table.css'; // Assuming you'll add a CSS file for styling

const SharingTable = () => {
    const [documents, setDocuments] = useState([]);
    const [sortField, setSortField] = useState('date_shared'); // Default sort field
    const [sortDirection, setSortDirection] = useState('desc'); // Default sort direction
    const [thumbnails, setThumbnails] = useState({}); // This will map folder names to arrays of thumbnail URLs
    const [hoveredFolder, setHoveredFolder] = useState(null);
    const hoverTimeout = useRef(null);  // Pridaný ref pre setTimeout
    const [displayedThumbnailIndex, setDisplayedThumbnailIndex] = useState(0);
    const rotateInterval = useRef(null);
    const [loadedFolders, setLoadedFolders] = useState([]);


    const handleMouseEnter = (folder) => {
        setDisplayedThumbnailIndex(0); // začnite s prvým thumbnailom
    
        if (!loadedFolders.includes(folder)) {
            hoverTimeout.current = setTimeout(() => {
                loadThumbnailsForFolder(folder);
                // Pridá interval, ktorý aktualizuje index thumbnailu každú sekundu
                rotateInterval.current = setInterval(() => {
                    setDisplayedThumbnailIndex(prevIndex => (prevIndex + 1) % (thumbnails[folder]?.length || 1));
                }, 1000);
                // Pridať folder do zoznamu načítaných folderov
                setLoadedFolders(prevFolders => [...prevFolders, folder]);
            }, 1000);
        } else {
            // Ak už sú náhľady načítané, začnite striedanie hneď
            rotateInterval.current = setInterval(() => {
                setDisplayedThumbnailIndex(prevIndex => (prevIndex + 1) % (thumbnails[folder]?.length || 1));
            }, 1000);
        }
    
        setHoveredFolder(folder);
    };
    
    
    const handleMouseLeave = () => {
        if (hoverTimeout.current) {
            clearTimeout(hoverTimeout.current);
        }
        if (rotateInterval.current) {
            clearInterval(rotateInterval.current); // Zastaví striedanie thumbnailov
        }
        setHoveredFolder(null);
    };

    const loadThumbnailsForFolder = async (folder) => {
        const storage = getStorage();
        const newThumbnails = { ...thumbnails };

        const doc = documents.find(d => d.folder === folder);
        const folderPath = `${doc.root_folder}/${doc.owner}/${doc.folder}`;
        const folderRef = ref(storage, folderPath);
        const imageUrls = [];
        const imagesList = await listAll(folderRef);

        for (const item of imagesList.items) {
            if (item.name.startsWith('thumb_')) {
                const imageUrl = await getDownloadURL(item);
                imageUrls.push(imageUrl);
            }
        }

        newThumbnails[folder] = imageUrls;
        setThumbnails(newThumbnails);
    };

    useEffect(() => {
        const fetchDocuments = async () => {
            try {
                const sharingCollectionRef = collection(firestore, "sharing");
                const querySnapshot = await getDocs(sharingCollectionRef);

                const fetchedDocuments = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id
                }));
                
                setDocuments(fetchedDocuments);
            } catch (error) {
                console.error("Error fetching documents:", error);
            }
        };

        fetchDocuments();
    }, []);

    useEffect(() => {
        const sortedDocs = [...documents].sort((a, b) => {
            const isAscending = sortDirection === 'asc';
    
            if (sortField === 'last_opened') {
                const lastViewA = a.view_logs?.slice(-1)[0] || "0";
                const lastViewB = b.view_logs?.slice(-1)[0] || "0";
                return isAscending ? lastViewA.localeCompare(lastViewB) : lastViewB.localeCompare(lastViewA);
            }
            
            if (sortField === 'view_counter') {
                const viewA = Number(a.view_counter || 0);
                const viewB = Number(b.view_counter || 0);
                return isAscending ? viewA - viewB : viewB - viewA;
            }
    
            return isAscending ? a[sortField] - b[sortField] : b[sortField] - a[sortField];
        });
    
        setDocuments(sortedDocs);
    }, [sortField, sortDirection]);
    
    

    const getLastOpenedDate = (doc) => {
        const lastDateString = doc.view_logs?.slice(-1)[0];
        if (lastDateString) {
            const date = new Date(lastDateString);
            return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}`;
        }
        return "Never";
    };
    
       


    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        <th onClick={() => {
                            setSortField('folder');
                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                        }}>
                            Folder Name
                        </th>
                        <th onClick={() => {
                            setSortField('date_shared');
                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                        }}>
                            Created
                        </th>
                        <th onClick={() => {
                            setSortField('last_opened');
                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                        }}>
                            Viewed
                        </th>
                        <th onClick={() => {
                            setSortField('view_counter');
                            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
                        }}>
                            Views
                        </th>
                        <th>
                            URL
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {documents.map(doc => (
                        <tr key={doc.id}>
                            <td>{doc.folder}</td>
                            <td>{new Date(doc.date_shared?.toDate()).toLocaleDateString()}</td>
                            <td>{getLastOpenedDate(doc)}</td>
                            <td>{doc.view_counter}</td>
                            <td className="tooltip">
                                <a 
                                  href={`https://inolens.sk/share?s=${doc.id}`} 
                                  target="_blank" 
                                  rel="noopener noreferrer"
                                  onMouseEnter={() => handleMouseEnter(doc.id)}
                                  onMouseLeave={handleMouseLeave}
                                >
                                    {`https://inolens.sk/share?s=${doc.id}`}
                                </a>
                                {hoveredFolder === doc.folder && (
                                    <div className="tooltip-content">
                                        {thumbnails[doc.folder] && (
                                            <img 
                                                src={thumbnails[doc.folder][displayedThumbnailIndex]} 
                                                alt={`Thumbnail ${displayedThumbnailIndex}`} 
                                                style={{width: '110px', height: '80px', objectFit: 'cover', animation: 'rotate 1s infinite'}} 
                                            />
                                        )}
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
    
}

export default SharingTable;
