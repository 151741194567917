import React from 'react';
import '../css/general-page.css';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import { useAuth } from '../contexts/AuthContext';

function Gdpr() {
   const { currentUser } = useAuth();
  return (
    <div className="background-none">
      {currentUser ? <NavBar /> : null}
    <div className="login-page">
    <div className="app-card" >

      
      <h1>Podmienky spracovania osobných údajov</h1>
      <h3>1. Úvod</h3>
        <p>
          <strong>1.1.</strong> Tieto Podmienky spracovania osobných údajov (ďalej len „podmienky“) sú platné pre všetkých užívateľov online služby, ktorú poskytuje INOLENS s.r.o, so sídlom na adrese Košice - mestská časť Staré Mesto, 040 01, IČO: 55 547 320, zapísaná v Obchodnom registri Mestského súdu Košice, vložka číslo: 57135/V (ďalej len „poskytovateľ“).
        </p>
        <p>
          <strong>1.2.</strong> Všeobecné podmienky, ktoré nie sú definované v tomto dokumente, sa riadia platnými právnymi predpismi Európskej únie a Slovenskej republiky, vrátane Nariadenia Európskeho parlamentu a Rady (EÚ) 2016/679 (GDPR).
        </p>
      <h3>2. Rozsah spracovania osobných údajov</h3>
        <p>
          <strong>2.1.</strong> Poskytovateľ zhromažďuje a spracúva osobné údaje užívateľov za účelom poskytovania služieb.
        </p>
        <p>
          <strong>2.2.</strong> Osobné údaje zahŕňajú, ale nie sú obmedzené na: meno, adresu, e-mail a fakturačné údaje.
        </p>
        <p>
          <strong>2.3.</strong> Poskytovateľ nezbiera ani nespracováva osobné údaje citlivej povahy.
        </p>
      <h3>3. Bezpečnosť a ochrana osobných údajov</h3>
        <p>
          <strong>3.1.</strong> Poskytovateľ vykonáva všetky potrebné kroky na zabezpečenie osobných údajov užívateľov a ich ochranu proti neoprávnenému prístupu, zneužitiu, zverejneniu, strate alebo deštrukcii.
        </p>
        <p>
          <strong>3.2.</strong> Užívateľ má právo pristupovať k svojim osobným údajom, opravovať ich, alebo požadovať ich vymazanie.
        </p>
      <h3>4. Zmena podmienok spracovania osobných údajov</h3>
        <p>
          <strong>4.1.</strong> Tieto podmienky sa môžu v budúcnosti aktualizovať a nadobúdajú platnosť 30 kalendárnych dní po ich zverejnení na webovom sídle poskytovateľa.
        </p>

        <p>1.7.2023</p>
        </div>
      
        
    
    </div>
    <div ><br /><br /></div>
    <Footer />
    </div>
    
  );
}

export default Gdpr;
