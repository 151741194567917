import React from 'react';
import '../css/general-page.css';
import Footer from '../components/Footer';
import { useAuth } from '../contexts/AuthContext';
import NavBar from '../components/NavBar';

function TermsAndConditions() {
    const { currentUser } = useAuth();


  return (
    <div className="background-none">
    {currentUser ? <NavBar /> : null}
    <div className="login-page">
    <div className="app-card" >
      <h1>Obchodné podmienky</h1>
      <h2>1. Úvod</h2>
        <p>
          <strong>1.1.</strong> Tieto Obchodné podmienky (ďalej len „podmienky“) sú platné pre všetkých užívateľov online služby INOLENS (ďalej len „služba“), ktorú poskytuje INOLENS s.r.o, so sídlom na adrese Košice - mestská časť Staré Mesto, 040 01, IČO: 55 547 320, zapísaná v Obchodnom registri Mestského súdu Košice, vložka číslo: 57135/V (ďalej len „poskytovateľ“).
        </p>
        <p>
          <strong>1.2.</strong> Služba je určená primárne pre realitných maklérov a realitné kancelárie a poskytuje automatizované spracovanie fotografií nehnuteľností pomocou umelej inteligencie.
        </p>
        
      
      <h2>2. Popis služby</h2>
        <p>
          <strong>2.1.</strong> Služba umožňuje užívateľom nahrať fotografie nehnuteľností, ktoré sú následne spracované pomocou umelej inteligencie za účelom vytvorenia inšpiračných obrázkov, poskytujúcich alternatívny pohľad na danú nehnuteľnosť.
        </p>
        <p>
          <strong>2.2.</strong> Cena za spracovanie jedného obrázka je závislá na vybranom balíčku predplatného a pohybuje sa približne v rozmedzí 0,08€ - 0,25€.
        </p>
        <p>
          <strong>2.3.</strong> Spracovanie obrázka stojí jeden kredit, ktorý sa zaúčtuje až po úspešnom vygenerovaní obrázka a uložení do užívateľovho profilu.
        </p>
        <p>
          <strong>2.4.</strong> Služba má svoje prirodzené limity. Medzi hlavné limity patrí subjektívna kvalita výstupu, ktorá je ovplyvnená náhodou a vlastnosťami vstupného obrázka. Niektoré výstupy môžu obsahovať logické chyby a atypické vizuálne prvky. Toto je dôsledkom technických vlastností generovania obrázkov a je prirodzenou súčasťou služby.
        </p>
        <p>
          <strong>2.5.</strong> Automaticky vygenerované výstupy sú označené vodoznakom, ktorý zrozumiteľne identifikuje obrazový materiál ako produkt AI aj zdroj kde bol obrazový materiál vytvorený. Toto označenie je používateľ povinný zachovať v záujme práv záujemcov o nehnuteľnosť. Týmto spôsobom sa zamedzí (aj) neumýselnemu zavádzaniu záujemcov o nehnuteľnosť a ochrane dobrého mena tejto služby.
        </p>
      
      <h2>3. Autorské práva</h2>
        <p>
          <strong>3.1.</strong> Obrazový materiál, ktorý je nahraný užívateľom, ako aj vygenerované obrázky sú z pohľadu autorských práv v plnom vlastníctve užívateľa.
        </p>
      
      <h2>4. Registrácia a vytvorenie užívateľského účtu</h2>
        <p>
          <strong>4.1.</strong> Na využívanie služby je potrebné, aby sa užívateľ zaregistroval a vytvoril si užívateľský účet.
        </p>
        <p>
          <strong>4.2.</strong> Užívateľ má povinnosť uviesť pravdivé a aktuálne informácie pri registrácii a má právo kedykoľvek zmeniť svoje kontaktné a fakturačné údaje.
        </p>
        <p>
          <strong>4.3.</strong> Užívateľ má právo svoj užívateľský účet kedykoľvek zmazať prostredníctvom svojho profilu.
        </p>
      
      <h2>5. Predplatné a platby</h2>
        <p>
          <strong>5.1.</strong> Služba je spoplatnená na báze mesačného predplatného.
        </p>
        <p>
          <strong>5.2.</strong> Poskytovateľ si vyhradzuje právo v budúcnosti meniť veľkosti balíčkov a cenu predplatného.
        </p>
        <p>
          <strong>5.3.</strong> Rôzne druhy predplatného sa líšia hlavne v mesačnej kvóte kreditov, prípadne iných parametroch, ktoré sa môžu v budúcnosti pridávať aj odoberať.
        </p>
        <p>
          <strong>5.4.</strong> Klient má právo svoje predplatné kedykoľvek zmeniť, alebo zrušiť. Zrušením predplatného ešte nezaniká používateľov profil, len sa mu pozastaví obnovovanie kreditu.
        </p>
      
      <h2>6. Skladovanie a dostupnosť dát</h2>
        <p>
          <strong>6.1.</strong> Služba garantuje uschovanie upravených obrázkov po dobu maximálne 3 mesiace.
        </p>
      
      <h2>7. Zodpovednosť a reklamácie</h2>
        <p>
          <strong>7.1.</strong> Vygenerované obrázky nie je možné reklamovať, pretože kvalita výstupu je subjektívna a je ovplyvnená technickými vlastnosťami generovania obrázkov.
        </p>
        <p>
          <strong>7.2.</strong> Požiadavka na prípadný reklamačný proces je vzhľadom na hodnotu ľudského času a nízku cenu za spracovanie obrázka nevýhodná pre klienta aj poskytovateľa.
        </p>
      
      <h2>8. Zmena obchodných podmienok</h2>
        <p>
          <strong>8.1.</strong> Tieto obchodné podmienky sa môžu v budúcnosti aktualizovať a nadobúdajú platnosť 30 kalendárnych dní po ich zverejnení na webovom sídle poskytovateľa.
        </p>
      
      <h2>9. Záverečné ustanovenia</h2>
        <p>
          <strong>9.1.</strong> Tieto Obchodné podmienky nadobúdajú účinnosť dňom 1.7.2023.
        </p>
        <p>
          <strong>9.2.</strong> Všeobecné podmienky, ktoré nie sú definované v tomto dokumente, sa riadia platnými právnymi predpismi Európskej únie a Slovenskej republiky.
        </p>

        </div>
        <div >
       
      </div>
    
    </div>
    <div ><br /><br /></div>
    <Footer />
    </div>
  );
}

export default TermsAndConditions;
