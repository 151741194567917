
import React from 'react';
//import { useParams } from 'react-router-dom';
import ConfirmSubscription from '../components/ConfirmSubscription';
import NavBar from '../components/NavBar';
import { useSubscription } from '../contexts/SubscriptionContext';
import Footer from '../components/Footer';



  function SubscriptionConfirmation() {
    const { selectedSubscription, userId } = useSubscription();

    return (
        <div>
          <NavBar />
          <h1>Potvrdenie predplatného</h1>
          <ConfirmSubscription selectedSubscription={selectedSubscription} userId={userId} />
          <Footer />
        </div>
      );
    }

export default SubscriptionConfirmation;
