
import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDoc, getDocs, query, where, updateDoc, doc } from 'firebase/firestore';
import { useSubscription } from '../contexts/SubscriptionContext';

function ConfirmSubscription() {
    const {AccountType, userId } = useSubscription();
  
    const [users, setUsers] = useState([]);
    //const [selectedUser, setSelectedUser] = useState('');
    const selectedUser = userId;

    const [accountTypes, setAccountTypes] = useState([]);
    //const [selectedAccountType, setSelectedAccountType] = useState('');

    const selectedAccountType = AccountType;
    const [message, setMessage] = useState('');
    const [configData, setConfigData] = useState(null);
    const [selectedConfig, setSelectedConfig] = useState(null);
    const [configDocId, setConfigDocId] = useState('');



  useEffect(() => {
    const fetchUsersAndConfig = async () => {
      const db = getFirestore();
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const users = usersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
      setUsers(users);
  
      const configSnapshot = await getDocs(collection(db, 'config'));
      const config = configSnapshot.docs[0].data();
      setAccountTypes(config.account_types);
      setConfigDocId(configSnapshot.docs[0].id);
    };
  
    fetchUsersAndConfig();
  }, []);
  

  useEffect(() => {
    const fetchConfigData = async () => {
      const db = getFirestore();
      const configDocRef = doc(db, 'config', configDocId);
      const configDocSnap = await getDoc(configDocRef);
  
      if (configDocSnap.exists()) {
        setConfigData(configDocSnap.data());
      }
    };
  
    if (configDocId) {
      fetchConfigData();
    }
  }, [configDocId]);
  

  useEffect(() => {
    if (configData) {
      loadSelectedConfigData();
    }
  }, [configData, selectedAccountType]);

  const loadSelectedConfigData = () => {
    if (selectedAccountType !== '' && configData) {
      const accountTypeIndex = parseInt(selectedAccountType);
      const accountType = accountTypes[accountTypeIndex];
      const monthlyCredit = configData.monthly_credit[accountTypeIndex];

      setSelectedConfig({
        accountType,
        monthlyCredit,
      });
    } else {
      setSelectedConfig({
        accountType: '',
        monthlyCredit: '',
      });
    }
  };


const applyChanges = async () => {
    if (!selectedUser) {
      setMessage('Vyberte užívateľa');
      return;
    }
  
    if (!configData) {
      setMessage('Konfiguračné dáta nie sú k dispozícii, skúste to znova');
      return;
    }
  
    const accountTypeIndex = parseInt(selectedAccountType);
    const configKeys = Object.keys(configData);
    const mappedConfig = {};
  
    configKeys.forEach(key => {
      const value = configData[key][accountTypeIndex];
  
      // Mapovanie hodnôt konfigurácie na hodnoty používateľa
      switch (key) {
        case 'adhoc_credit': mappedConfig.user_adhoc_credit = value; break;
        case 'account_types': mappedConfig.account_type = value; break;
        case 'account_types_cz': mappedConfig.account_type_cz = value; break;
        case 'account_types_sk': mappedConfig.account_type_sk = value; break;
        case 'adhoc_credit_price_eur': mappedConfig.adhoc_credit_price_eur = value; break;
        case 'adhoc_credit_price_czk': mappedConfig.adhoc_credit_price_czk = value; break;
        case 'monthly_credit_price_eur': mappedConfig.monthly_credit_price_eur = value; break;
        case 'monthly_credit_price_czk': mappedConfig.monthly_credit_price_czk = value; break;
        case 'render_price_std': mappedConfig.render_price_std = value; break;
        case 'render_price_fast': mappedConfig.render_price_fast = value; break;
        case 'monthly_credit': mappedConfig.monthly_credit = value; break;
        case 'monthly_credit_unused_quota': mappedConfig.monthly_credit_unused_quota = value; break;
        case 'account_team_size': mappedConfig.account_team_size = value; break;
        case 'job_size_limit': mappedConfig.job_size_limit = value; break;
//        case '': mappedConfig. = value; break;

        // ... Pridajte ďalšie prípady pre zvyšok hodnôt konfigurácie
        default:
          break;
      }
    });
  
    const db = getFirestore();
    const userDocRef = doc(db, 'users', selectedUser);
    await updateDoc(userDocRef, mappedConfig);
  
    setMessage('Zmena údajov bola úspešne aplikovaná');
  };


  return (
    <div>
      <div>{message}</div>
      <div>
        <p>UserId: {userId}</p>
        <p>Account Type: {AccountType}</p>
      </div>
      <button onClick={() => applyChanges(userId, selectedAccountType)}>
        Potvrdiť
      </button>
    </div>
  );
  
}

export default ConfirmSubscription;
