
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import { useAuth } from '../contexts/AuthContext';

export function ConfirmRegistrationPage() {
  const codeInputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { confirmRegistration } = useAuth();
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();

    const code = codeInputRefs
      .map((ref) => ref.current.value)
      .join('');

    try {
      setError('');
      setLoading(true);
      await confirmRegistration(code);
      navigate('/welcome');
    } catch (error) {
      setError('Nesprávny PIN kód');
    }

    setLoading(false);
  }

  return (
    <Container className="mt-5">
      <h1>Registrácia prebehla úspešne!</h1>
      <p>Ešte prosím overte Vašu emailovú adresu zadaním PIN kódu, ktorý sme Vám práve zaslali.</p>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formBasicCode">
          {codeInputRefs.map((ref, idx) => (
            <Form.Control
              key={idx}
              type="text"
              maxLength="1"
              style={{ width: '3rem', margin: '0.5rem' }}
              ref={ref}
              required
            />
          ))}
        </Form.Group>
        <Button type="submit" disabled={loading} className="mt-3">
          Potvrdiť
        </Button>{' '}
        <Button as={Link} to="/register" variant="secondary" className="mt-3">
          Späť
        </Button>
      </Form>
    </Container>
  );
}
