import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { firestore } from "../firebase";

const ParentAccountCreditStatus = () => {
  const { currentUser } = useAuth();
  const [parentAccountCredit, setParentAccountCredit] = useState(null);
  const [creditSpentThisMonth, setCreditSpentThisMonth] = useState(null);
  const [invitationId, setInvitationId] = useState(null);
  const [receivedInvitations, setReceivedInvitations] = useState([]);
  const [teamName, setTeamName] = useState([]);
  const [hasActiveInvitation, setHasActiveInvitation] = useState(false);


  useEffect(() => {
    const fetchParentAccountCredit = async () => {
      if (currentUser) {
        const userSnapshot = await firestore.collection("users").doc(currentUser.uid).get();
        const userData = userSnapshot.data();

        if (userData.received_invitations) {
          const invitationsPromises = userData.received_invitations.map(async (invitationId) => {
            const invitationSnapshot = await firestore.collection("team_invitations").doc(invitationId).get();
            return invitationSnapshot.data();
          });

          const invitations = await Promise.all(invitationsPromises);
          setReceivedInvitations(invitations);

          const activeInvitation = invitations.find((invitation) => invitation.status === "active");

          if (activeInvitation) {
            const parentSnapshot = await firestore.collection("users").doc(activeInvitation.uid_parent).get();
            const parentData = parentSnapshot.data();
            setParentAccountCredit(parentData.credit);
            setCreditSpentThisMonth(activeInvitation.credit_spent_this_month);
            setInvitationId(activeInvitation.invitation_id);
            setTeamName(activeInvitation.team_name);
            setHasActiveInvitation(true);
          }
        }
      }
    };

    fetchParentAccountCredit();
  }, [currentUser]);

return (
    <div>
      {hasActiveInvitation && (
        <>
            <br></br>
            <hr></hr>
            <h2><strong>Zdieľaný účet:</strong> {teamName}</h2>
            <p><strong>Stav kreditu rodičovského účtu:</strong> {parentAccountCredit}</p>
            <p><strong>Moje čerpanie kreditu tento mesiac:</strong> {creditSpentThisMonth}</p>
            <p><strong>ID pozvánky:</strong> {invitationId}</p>
        </>
      )}
    </div>
  );
};

export default ParentAccountCreditStatus;
