import React, { useEffect } from 'react';
import '../css/image-animation.css'; 
import introBase from '../images/intro/intro-base.webp';
import introApp from '../images/intro/intro-app.webp';
import introHeadline from '../images/intro/intro-headline.webp';

const ImageAnimation = () => {
  useEffect(() => {
    // Prednačítavanie obrázkov
    [introBase, introApp, introHeadline].forEach(src => {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = src;
      document.head.appendChild(link);
    });

    // Nastavenie oneskorenia pre zobrazenie obrázkov
    setTimeout(() => {
      document.querySelector('.image-headline').style.display = 'block';
      document.querySelector('.image-headline').style.opacity = '1';

      setTimeout(() => {
        document.querySelector('.image-app').style.display = 'block';
        document.querySelector('.image-app').style.opacity = '1';
        document.querySelector('.image-app').style.left = '0';
      }, 1000);
    }, 1000);
  }, []);

  return (
    <div className="image-container">
      <img src={introBase} alt="ukážka výstupu aplikácie INOLENS - pohľad na exteriér záhradnej chatky s bazénom" className="image-base" style={{ opacity: 1 }} />
      <img src={introHeadline} alt="Kratky text ktory hovori, že aplikácia INOLENS odkrýva hodnotu starčích nehnuteľností" className="image-headline" />
      <img src={introApp} alt="náhľad na mobilné zariadenie ktoré má načítanú aplikáciu INOLENS" className="image-app" />
    </div>
  );
}

export default ImageAnimation;
