import React, { useState, useEffect } from 'react';
import { getFirestore } from "firebase/firestore";
import { doc, getDoc } from "firebase/firestore";
import '../css/general-page.css'

const db = getFirestore();

function QueueSizeComponent() {
    const [waitingTime, setWaitingTime] = useState(0);
    
    const fetchQueueData = async () => {
        const statusDoc = await getDoc(doc(db, "status", "C2OGlCPYSyKqSfp3M8Uf"));
        
        if (statusDoc.exists()) {
            let counter_in = statusDoc.data().counter_in;
            let counter_out = statusDoc.data().counter_out;

            const queueSize = counter_in - counter_out;
            const waitingTimeMinutes = Math.ceil((queueSize * 30) / 60); // Convert seconds to minutes
            
            setWaitingTime(waitingTimeMinutes);
        } else {
            console.log("No such document!");
        }
    }

    useEffect(() => {
        fetchQueueData(); // Fetch data immediately once

        const timer = setInterval(() => {
            fetchQueueData();
        }, 15000);

        // Clean up interval on unmount
        return () => clearInterval(timer);
    }, []);

    let waitingTimeString;
    if (waitingTime <= 5) {
        waitingTimeString = "menej ako 5 minút";
    } else {
        const hours = Math.floor(waitingTime / 60);
        const minutes = waitingTime % 60;
        waitingTimeString = `približne ${hours}h a ${minutes}m`;
    }

    return (
        <div className="queue-container">
            <p>Aktuálne čakanie:</p>
            <div className="queue-time">
                <p className="loading-text">{waitingTimeString}</p>
            </div>
        </div>
    );
}

export default QueueSizeComponent;
     