import React, { useState, useEffect } from 'react';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const CreditStatus = ({ userId }) => {
  const [creditData, setCreditData] = useState({ credit: 0, monthly_credit: 0 });

  useEffect(() => {
    const fetchUserData = async () => {
      const db = getFirestore();
      const userDocRef = doc(db, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setCreditData({ credit: userData.credit, monthly_credit: userData.monthly_credit });
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  const { credit, monthly_credit } = creditData;
  const percentage = Math.round((credit / monthly_credit) * 100);
  const overPercentage = percentage > 100 ? percentage - 100 : 0;
  const safePercentage = isNaN(percentage) ? 0 : percentage;

  return (
    <div>
      <p>
        Aktuálny kredit je {credit}/{monthly_credit} ({percentage}%)
      </p>
      <progress max="100" value={safePercentage - overPercentage}></progress>
      {overPercentage > 0 && (
        <>
          <span>+{overPercentage}%</span>
          <progress max="100" value={overPercentage}></progress>
        </>
      )}
    </div>
  );
};

export default CreditStatus;
