import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const withAdminAuth = (WrappedComponent) => {
    return function AdminAuthComponent(props) {
        const { currentUser } = useAuth();
        const [isAdmin, setIsAdmin] = React.useState(false);
        const [loading, setLoading] = React.useState(true);

        React.useEffect(() => {
            const checkAdminStatus = async () => {
                if (currentUser) {
                    const db = getFirestore();
                    const userRef = doc(db, 'users', currentUser.uid);
                    const userDoc = await getDoc(userRef);
                    const userData = userDoc.data();

                    if (userData && userData.admin) {
                        setIsAdmin(true);
                    }
                }
                setLoading(false);
            };

            checkAdminStatus();
        }, [currentUser]);

        if (loading) {
            return <div>Loading...</div>;
        }

        if (!isAdmin) {
            return <div>Nemáte prístup k tejto stránke.</div>;
        }

        return <WrappedComponent {...props} />;
    };
};

export default withAdminAuth;
