
import React, { useState, useEffect } from 'react';
import { storage } from '../firebase';
import NavBar from '../components/NavBar';
import { useAuth } from '../contexts/AuthContext';
import { firestore } from "../firebase";
import CustomModalImage from '../components/CustomModalImage';
import Footer from '../components/Footer';

import '../css/general-page.css';

const sortFolders = (folders, order) => {
  return folders.sort((a, b) => {
    if (order === 'asc') {
      return new Date(a.latestDate) - new Date(b.latestDate);
    } else {
      return new Date(b.latestDate) - new Date(a.latestDate);
    }
  });
};

const filterFolders = (folders, text) => {
  return folders.filter((folder) =>
    folder.folder.toLowerCase().includes(text.toLowerCase()) || folder.jobName.toLowerCase().includes(text.toLowerCase())
  );
};

const ImageGalleryPageShared = () => {
  const { currentUser } = useAuth();
  const [folders, setFolders] = useState([]);
  const [orderByDate, setOrderByDate] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = useState('');
  const [debouncedFilterText, setDebouncedFilterText] = useState('');
  const itemsPerPage = 4;
  const [isLoading, setIsLoading] = useState(true);
  const [invitationId, setInvitationId] = useState(null);
  const [receivedInvitations, setReceivedInvitations] = useState([]);
  const [selectedInvitation, setSelectedInvitation] = useState(null); // nový stav pre aktuálne zvolenú pozvánku
  const [path, setPath] = useState(null);

   const handleInvitationChange = (e) => {
    setSelectedInvitation(e.target.value); // upraviť stav podľa zvolenej pozvánky
  };

  useEffect(() => {
    const loadFolders = async () => {
      const currentUserRef = firestore.collection("users").doc(currentUser.uid);
      const currentUserSnapshot = await currentUserRef.get();
      const userData = currentUserSnapshot.data();
      const invitationsData = userData.active_invitations || [];

      const invitationPromises = invitationsData.map((invitationId) =>
      firestore
        .collection("team_invitations")
        .doc(invitationId)
        .get()
        .then((doc) => ({
          id: invitationId,
          email: doc.data().email,
        }))
    );

    const invitations = await Promise.all(invitationPromises);
    setReceivedInvitations(invitations);


      //setReceivedInvitations(userData.active_invitations || []); // pridajte túto časť

      if (!selectedInvitation) { // ak nie je zvolená žiadna pozvánka, nastaviť na prvú
        setSelectedInvitation(userData.active_invitations[0]);
        return; 
      }
      
      const userJobsFolderPath = `shared_jobs/${selectedInvitation}/`; // použiť zvolenú pozvánku
      setPath (userJobsFolderPath);
      console.log(userJobsFolderPath);
      const storageRef = storage.ref(userJobsFolderPath);
      const res = await storageRef.listAll();

      const folderPromises = res.prefixes.map(async (folderRef) => {
        const folderRes = await folderRef.listAll();
        const imagePromises = folderRes.items.map((item) => ({
          urlPromise: item.getDownloadURL(),
          metadataPromise: item.getMetadata(),
        }));

        const logFile = folderRes.items.find((item) => item.name.endsWith('.txt')); // Find the log file
          let jobName = folderRef.name; // Default job name to folder name
          if (logFile) {
            const logFileUrl = await logFile.getDownloadURL();
            const response = await fetch(logFileUrl);
            const logFileContent = await response.text();
            const match = logFileContent.match(/name: (.*)/); // Parse job name from log file
            if (match) {
              jobName = match[1];
            }
          }

        const imageMetadata = await Promise.all(imagePromises.map((img) => img.metadataPromise));
        let images = await Promise.all(imagePromises.map((img) => img.urlPromise));
        // Filter images to only include .jpg, .png etc and exclude .txt
        images = images.filter(url => (url.includes('.jpg') || url.includes('.png') || url.includes('.jpeg') || url.includes('.jfif')));

        const latestDate = imageMetadata
          .map((metadata) => metadata.timeCreated)
          .sort((a, b) => new Date(b) - new Date(a))[0];

        return {
          folder: folderRef.name, jobName,
          images: images.filter((url) => url.includes("thumb_")),
          fullResImages: images.filter((url) => !url.includes("thumb_") && !url.includes("_input_")),
          inputImages: images.filter((url) => !url.includes("thumb_") && url.includes("input_")),
          latestDate,
        };
      });

      let folders = await Promise.all(folderPromises);
      folders = sortFolders(folders, orderByDate);
      setFolders(folders);
      setIsLoading(false); // koniec načítania
    };

    if (currentUser) {
      setIsLoading(true); // začiatok načítania
      loadFolders();      
    }

  }, [currentUser, orderByDate, selectedInvitation]);;

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedFilterText(filterText);
    }, 400);

    return () => {
      clearTimeout(timerId);
    };
  }, [filterText]);

  const handleSortToggle = () => {
    setOrderByDate(orderByDate === 'asc' ? 'desc' : 'asc');
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    const totalPages = Math.ceil(filteredFolders.length / itemsPerPage);
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <div style={{ display: 'block', justifyContent: 'center', marginTop: '1rem', textAlign: 'center' }}>
        {pageNumbers.map((number) => (
          <button
           className="page-button"
            key={number}
            onClick={() => handlePageChange(number)}
            style={{
              background: currentPage === number ? 'white' : '#b7b7b7',
            
            }}
          >
            {number}
          </button>
        ))}
      </div>
    );
    
  };


  const filteredFolders = filterFolders(folders, debouncedFilterText);
  const totalPages = Math.ceil(filteredFolders.length / itemsPerPage);
  const currentPageValid = currentPage <= totalPages ? currentPage : 1;
  const indexOfLastItem = currentPageValid * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFolders = filteredFolders.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="background-none">
      <NavBar />
      <div className="page">
        <div className="app-card">
          <h1>Projekty v tíme</h1>
          <span>Člen tímu: </span>
          <select value={selectedInvitation} onChange={handleInvitationChange}>
            {receivedInvitations.map((invitation, index) => (
              <option value={invitation.id} key={index}>
                {invitation.email}
              </option>
            ))}
          </select>

          <hr></hr>
          <button className="input-button" onClick={handleSortToggle}>
            Zoradiť podľa dátumu: {orderByDate === 'asc' ? 'Vzostupne' : 'Zostupne'}
          </button>
          <input
            type="text"
            placeholder="Filtrovať názvy priečinkov"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
          />
          {isLoading ? (
            <div className="loading-container">
              <div className="loading-ball"></div>
            </div>
          ) : (
            currentFolders.map((folder, index) => {
              const thumbnailCount = Math.min(3, folder.images.length);
              return (
                <div key={index} style={{ marginTop: '1rem', textAlign: 'left' }}>
                  <span style={{ fontWeight: '800' }}>
                      {folder.jobName} ({folder.fullResImages.length} exportov)
                    </span>
                  <span style={{ marginLeft: "8px", fontWeight: "normal" }}>
                    {new Date(folder.latestDate).toLocaleDateString()}
                  </span>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {folder.images.slice(0, thumbnailCount).map((url, i) => (
                      <CustomModalImage
                        cesta={path}
                        key={i}
                        small={url}
                        large={folder.fullResImages}
                        input={folder.inputImages}
                        currentUser={currentUser.uid}
                        currentIndex={i}
                        folder={folder.folder}
                        alt="Thumbnail"
                        style={{
                          width: '80px',
                          height: '80px',
                          objectFit: 'cover',
                          marginBottom: '1rem',
                          marginRight: '15px',
                        }}
                      />
                    ))}
                  </div>
                  {index < filteredFolders.length - 1 && <hr />}
                </div>
              );
            })
          )}
          {!isLoading && renderPageNumbers()}
        </div>
      </div>
      <div >
       <br /><br /><br />
      </div>
      <Footer />
    </div>
  );
}; 

export default ImageGalleryPageShared;


/*
{thumbnailCount < folder.images.length && (
  <div style={{ display: 'flex', alignItems: 'left', backgroundColor: 'rgba(255, 255, 255, 0.0)', }}>
    <span style={{ fontWeight: '600' }}>+{folder.images.length - thumbnailCount -1}</span>
    <span style={{ marginLeft: '4px' }}>ďalšie</span>
  </div>
)}
*/
   
