import React, { useState, useEffect } from 'react';
import { getFirestore, collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import withAdminAuth from '../contexts/withAdminAuth';
import { useTable, useSortBy } from 'react-table';
import { getStorage, ref, listAll, getDownloadURL } from 'firebase/storage';
import { getMetadata } from 'firebase/storage';



const AdminProtectedPanel = withAdminAuth(AdminPanel);


function AdminPanel() {
    const [users, setUsers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const [creditToAdd, setCreditToAdd] = useState(0);
    const [userJobs, setUserJobs] = useState([]);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [jobCreationDates, setJobCreationDates] = useState({});




    const data = React.useMemo(() => users, [users]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Email',
                accessor: 'email',
            },
            {
                Header: 'Credit',
                accessor: 'credit',
            },
            {
                Header: 'Blok',
                accessor: 'credit_blocked',
            },
            {
                Header: 'Account Type',
                accessor: 'account_type',
            },
            {
                Header: 'Detail',
                accessor: 'detail',
                Cell: ({ row }) => (
                    <button onClick={() => handleOpenModal(row.original)}>
                        👁️
                    </button>
                ),
            }
        ],
        []
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data }, useSortBy);

    const fetchUsers = async () => {
        const db = getFirestore();
        const usersCollection = collection(db, 'users');
        const usersSnapshot = await getDocs(usersCollection);
        const usersData = usersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
        setUsers(usersData);
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleOpenModal = (details) => {
        setUserDetails(details);
        fetchUserJobs(details.id);
        setIsModalOpen(true);
    };
   

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setUserDetails(null);
    };

    const handleIncreaseCredit = async () => {
        if (userDetails && userDetails.id) {
            const db = getFirestore();
            const userDoc = doc(db, 'users', userDetails.id);

            try {
                await updateDoc(userDoc, {
                    credit: userDetails.credit + parseFloat(creditToAdd)
                });

                // Close the modal after updating the credit
                setIsModalOpen(false);
                setUserDetails(null);
                
                // Refresh users list after updating the credit
                fetchUsers();
            } catch (error) {
                console.error("Error updating user credit:", error);
            }
        }
    };

    /*const fetchUserJobs = async (userId) => {
        const storage = getStorage();
        const userJobsRef = ref(storage, `users_jobs/${userId}`);
        const jobsSnapshot = await listAll(userJobsRef);
        
        // Get unique folders (based on prefixes)
        const folders = new Set(jobsSnapshot.prefixes.map(prefix => prefix.name));
        
        const jobCreationDates = {};
        
        for (const folder of folders) {
            const folderRef = ref(storage, `users_jobs/${userId}/${folder}`);
            const imagesInFolder = await listAll(folderRef);
            for (const item of imagesInFolder.items) {
                if (item.name.startsWith('thumb')) {
                    const metadata = await getMetadata(item);
                    jobCreationDates[folder] = new Date(metadata.timeCreated);
                    break; // We only need one image's metadata per folder
                }
            }
        }
        
        // Update the userJobs state with these folders and creation dates
        setUserJobs([...folders]);
        setJobCreationDates(jobCreationDates);

        const db = getFirestore();
        const userDoc = doc(db, 'users', userId);
        const jobsArray = [...folders].map(folderName => ({
            job_id: folderName,
            date_created: jobCreationDates[folderName]
        }));
        await updateDoc(userDoc, {
            my_jobs: jobsArray
        });
    };*/
    const getJobNameFromLog = async (folderRef) => {
        const folderRes = await listAll(folderRef);
        const logFile = folderRes.items.find((item) => item.name.endsWith('.txt')); 
        let jobName = folderRef.name; 
        if (logFile) {
          const logFileUrl = await getDownloadURL(logFile);
          const response = await fetch(logFileUrl);
          const logFileContent = await response.text();
          const match = logFileContent.match(/name: (.*)/);
          if (match) {
            jobName = match[1];
          }
        }
        return jobName;
      };
      
      const fetchUserJobs = async (userId) => {
          const storage = getStorage();
          const userJobsRef = ref(storage, `users_jobs/${userId}`);
          const jobsSnapshot = await listAll(userJobsRef);
          
          // Get unique folders (based on prefixes)
          const folders = new Set(jobsSnapshot.prefixes.map(prefix => prefix.name));
          
          const jobCreationDates = {};
          const jobNames = {};
          
          for (const folder of folders) {
              const folderRef = ref(storage, `users_jobs/${userId}/${folder}`);
              const imagesInFolder = await listAll(folderRef);
              for (const item of imagesInFolder.items) {
                  if (item.name.startsWith('thumb')) {
                      const metadata = await getMetadata(item);
                      jobCreationDates[folder] = new Date(metadata.timeCreated);
                      break; // We only need one image's metadata per folder
                  }
              }
              const jobName = await getJobNameFromLog(folderRef);
              jobNames[folder] = jobName;
          }
          
          // Update the userJobs state with these folders and creation dates
          setUserJobs([...folders]);
          setJobCreationDates(jobCreationDates);
      
          const db = getFirestore();
          const userDoc = doc(db, 'users', userId);
          const jobsArray = [...folders].map(folderName => ({
              job_id: folderName,
              job_name: jobNames[folderName],
              date_created: jobCreationDates[folderName]
          }));
          await updateDoc(userDoc, {
              my_jobs: jobsArray
          });
      };
      
      

    const sortedUserJobs = [...userJobs].sort((a, b) => {
        const dateA = jobCreationDates[a];
        const dateB = jobCreationDates[b];
        
        // Ak jeden z dátumov neexistuje, vráťte vhodnú hodnotu
        if (!dateA) return 1; 
        if (!dateB) return -1;
    
        // Zoradenie od najnovšieho po najstaršie
        return dateB - dateA;
    });
    
    
    const fetchJobImages = async (userId, jobId) => {
        const storage = getStorage();
        const jobImagesRef = ref(storage, `users_jobs/${userId}/${jobId}`);
        const imagesSnapshot = await listAll(jobImagesRef);
    
        const images = await Promise.all(
            imagesSnapshot.items
            .filter(item => !item.name.startsWith('thumb'))
            .map(async (item) => {
                const downloadURL = await getDownloadURL(item);
                return {
                    imageName: item.name,
                    url: downloadURL
                };
            })
        );
    
        return images;
    };

    const handleOpenImageModal = async (userId, jobId) => {
        const images = await fetchJobImages(userId, jobId);
        setSelectedJob(images);
        setIsImageModalOpen(true);
    };

    const handleEscKeyPress = (e) => {
        if (e.key === "Escape" && isModalOpen) {
            handleCloseModal();
        }
    };
    
    useEffect(() => {
        window.addEventListener('keydown', handleEscKeyPress);
    
        // Cleanup function:
        return () => {
            window.removeEventListener('keydown', handleEscKeyPress);
        };
    }, [isModalOpen]);
    
    
    

    return (
        <>
            <table {...getTableProps()} style={{ borderCollapse: 'collapse', border: '1px solid black', fontSize: '11pt' }}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    style={{
                                        borderBottom: 'solid 1px black',
                                        background: 'aliceblue',
                                        color: 'black',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                                    </span>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()} style={{ backgroundColor: row.index % 2 === 0 ? 'lightgray' : 'white' }}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} style={{ padding: '1px', border: 'solid 1px gray' }}>
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {isModalOpen && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    maxHeight: '80vh', // Max height set to 80% of the viewport height
                    overflowY: 'auto', // Allow scrolling in Y direction
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',   // This line is changed
                    paddingTop: '5vh',         // Some top padding so modal isn't stuck to the very top
                }}>
                    <div style={{ 
                        width: '70%',
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                    }}>
                        <button onClick={handleCloseModal} style={{ float: 'right' }}>X</button>
                        <pre>{JSON.stringify(userDetails, null, 2)}</pre>

                        {/* Credit Increase Form */}
                        <div style={{ marginTop: '20px' }}>
                            <label>
                                Navýšiť kredit:
                                <input 
                                    type="number" 
                                    value={creditToAdd} 
                                    onChange={(e) => setCreditToAdd(e.target.value)} 
                                    style={{ marginLeft: '10px' }}
                                />
                            </label>
                            <button onClick={handleIncreaseCredit} style={{ marginLeft: '10px' }}>OK</button>
                        </div>

                        {/* Move the table inside the main modal container */}
                        <div>
                            <h3>Zákazky používateľa</h3>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Job ID</th>
                                        <th>Dátum</th>
                                    </tr>
                                </thead>
                                <tbody>
                                 

                              {sortedUserJobs.map(folderName => (
                                    <tr key={folderName}>
                                        <td>{folderName}</td>
                                        <td>{jobCreationDates[folderName] && new Date(jobCreationDates[folderName]).toLocaleString('sk-SK', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' })}</td>
                                        <td>
                                                <button onClick={() => handleOpenImageModal(userDetails.id, folderName)} style={{ background: 'none', border: 'none', color: 'blue', cursor: 'pointer' }}>
                                                    Zobraziť
                                                </button>
                                            </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    </div>  
                </div>    
            )}

            {isImageModalOpen && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    maxHeight: '80vh', // Max height set to 80% of the viewport height
                    overflowY: 'auto', // Allow scrolling in Y direction
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',   // This line is changed
                    paddingTop: '5vh',         // Some top padding so modal isn't stuck to the very top
                }}>
                    <div style={{ 
                        width: '70%',
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '10px',
                        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
                    }}>
                        <button onClick={() => setIsImageModalOpen(false)} style={{ float: 'right' }}>X</button>
                        {selectedJob && selectedJob.map(image => (
                            <div key={image.imageName} style={{ marginBottom: '10px' }}>
                                <img src={image.url} style={{ maxWidth: '320px', width: '100%' }} alt={image.imageName} />
                            </div>
                        ))}
                    </div>
                </div>
            )}


        </>
    );
}

export default AdminProtectedPanel;
