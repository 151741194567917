import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
//import { resetPassword } from "../contexts/AuthContext";
import { Form, Button, Card, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavBarPublic from '../components/NavBarPublic';
import logo from '../images/logo-blue.png'; // Importujte logo
import Footer from '../components/Footer';

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const { resetPassword } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      setMessage("");
      setError("");
      await resetPassword(email);
      setMessage("Na zadaný email sme Vám zaslali pokyny pre obnovenie hesla");
    } catch (error) {
      setError(`Heslo sa nepodarilo obnoviť, skontrolujte či ste zadali správnu emailovú adresu`);
    }
  };

  return (
    <div className="background-none">
       <NavBarPublic /> 
      <div className="login-page">
      <Card style={{ maxWidth: '320px', }} className="login-card">
        <Card.Body className="center" >
        <a href="/">
          <img src={logo} alt="Logo" className="app-logo" />
        </a>
          <h3 className="login-title text-center mb-4">Obnovenie hesla</h3>
          <p style={{ width: '100%', }}>Pre obnovenie hesla do Vášho účtu zadajte svoju emailovú adresu:</p>
          {error && <Alert variant="danger">{error}</Alert>}
          
          <form onSubmit={handleSubmit}>
                <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                />
                <button type="submit">Resetovať heslo</button>
            </form>
            {error && <p>{error}</p>}
            {message && <p>{message}</p>}

          <div className="login-register">
            Už ste si heslo obnovili? <Link className="login-register-link" to="/login">Prihlásiť sa</Link>
          </div>
        </Card.Body>
      </Card>   
      <Footer /> 
    </div>

    </div>
  );
}
