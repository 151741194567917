import React, { useState, useEffect } from "react";
import { useAuth } from "../contexts/AuthContext";
import { firestore } from '../firebase'; // Importujte konfiguráciu Firestore
import { arrayRemove } from "firebase/firestore"; 
import { Modal } from 'react-bootstrap';

import { useInvitations } from '../contexts/InvitationsContext';
import "../css/invitations-table.css";

const InvitationsTable = () => {
  const { currentUser } = useAuth();
  const uid = currentUser ? currentUser.uid : null;
  const [invitations, setInvitations] = useState([]);
  const { setInvitationsChanged } = useInvitations();
  const { invitationsChanged, usedInvitations } = useInvitations();

  const [selectedInvitation, setSelectedInvitation] = useState(null);
  
  const showDetails = (index) => {
    setFadeAnim("fade-in");
    setSelectedInvitation(invitations[index]);
  };


  const [fadeAnim, setFadeAnim] = useState("");


  const deleteInvitation = async (index) => {
    const invitationToDelete = invitations[index];
    const invitationsSnapshot = await firestore
      .collection("team_invitations")
      .where("uid_parent", "==", uid)
      .where("email", "==", invitationToDelete.email)
      .get();
  
    if (!invitationsSnapshot.empty) {
      const invitationDoc = invitationsSnapshot.docs[0];
      // Zmazať pozvánku z Firestore
      await firestore.collection("team_invitations").doc(invitationDoc.id).delete();
  
      // Zmazať záznam v poli account_members
      await firestore.collection("users").doc(uid).update({
        account_members: arrayRemove(invitationToDelete.email),
        invitations_ids: arrayRemove(invitationToDelete.invitation_id)
      });
  
      // Získať UID člena tímu na základe jeho e-mailovej adresy
      const userSnapshot = await firestore.collection("users").where("email", "==", invitationToDelete.email).get();
  
      // Zmazať pozvánku z received_invitations u člena tímu
      if (!userSnapshot.empty) {
        const userDoc = userSnapshot.docs[0];
        await firestore.collection("users").doc(userDoc.id).update({
          received_invitations: arrayRemove(invitationToDelete.invitation_id)
        });
      } else {
        console.error("Nebolo možné nájsť člena tímu pre zadaný e-mail.");
      }
  
      // Odstrániť pozvánku zo stavu
      setInvitations(invitations.filter((_, i) => i !== index));
  
      // aktualizovať invitations context
      setInvitationsChanged((prev) => !prev);
    } else {
      console.error("Nebolo možné nájsť pozvánku na zmazanie.");
    }
  };
  



  useEffect(() => {
    const fetchInvitations = async () => {
      if (uid) {
        const invitationsSnapshot = await firestore
          .collection("team_invitations")
          .where("uid_parent", "==", uid)
          .get();

        const invitationsData = invitationsSnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            date: data.invitation_date.toDate().toLocaleDateString(),
            email: data.email,
            invitation_id: data.invitation_id,
            status: data.status,
          };
        });

        setInvitations(invitationsData);
      }
    };

    fetchInvitations();
  }, [uid, invitationsChanged]);

  
  if (usedInvitations === 0) {
    return null;
  }

  return (
    
    <div>       
        
        <Modal
        show={selectedInvitation !== null}
        onHide={() => {
          setFadeAnim("fade-out");
          setTimeout(() => {
            setSelectedInvitation(null);
            setFadeAnim("");
          }, 330);
        }}
        centered
        style={{ maxWidth: "90%" }}
        className={fadeAnim}
        onAnimationEnd={() => {
          if (fadeAnim === "fade-out") {
            setFadeAnim("");
          }
        }}
      >
        <Modal.Header closeButton>
            <Modal.Title>Detail pozvánky</Modal.Title>
        </Modal.Header>
        
        <Modal.Body style={{ whiteSpace: "nowrap" }}>

            {selectedInvitation && (
            <>
                <p>
                <strong>Dátum:</strong> {selectedInvitation.date}
                </p>
                <p>
                <strong>E-mail:</strong> {selectedInvitation.email}
                </p>
                <p>
                <strong>ID pozvánky:</strong> {selectedInvitation.invitation_id}
                </p>
                
                <p>
                <strong>Stav:</strong> {selectedInvitation.status}
                </p>
            </>
            )}
        </Modal.Body>
        <Modal.Footer>
            <button onClick={() => {
                setSelectedInvitation(null); 
                deleteInvitation(invitations.findIndex(inv => inv.email === selectedInvitation.email))}
                }>
            Zmazať
            </button>
            <button onClick={() => setSelectedInvitation(null)}>Zavrieť</button>
        </Modal.Footer>
      </Modal>

      <h2>Zoznam odoslaných pozvánok</h2>
      
      <table className="invitations-table">
        <thead>
          <tr>
            <th>E-mail</th>           
            <th>Stav</th>
            <th>Akcia</th>
          </tr>
        </thead>
        <tbody>
          {invitations.map((invitation, index) => (
            <tr key={index}>
              <td className="email-column">{invitation.email}</td>

              <td>{invitation.status}</td>
              <td>
              <button onClick={() => showDetails(index)}>Detail</button>
              </td>
            </tr>
          ))}
        </tbody>
        
      </table>
      <hr></hr> 
    </div>
  );
};

export default InvitationsTable;
