import React, { useEffect, useState } from 'react';
import { auth } from '../firebase'
import firebase from '../firebase';
import './stripe.css'
import { useAuth } from '../contexts/AuthContext';
import { loadStripe } from "@stripe/stripe-js";
import NavBar from '../components/NavBarPublic';
import Footer from '../components/Footer';
import StripeCustomerPortal from '../stripe/StripeCustomerPortal';
import InvoicesList from '../components/InvoicesList';


const Home = () => {
    const db = firebase.firestore();
    const [products, setProducts] = useState([])
    const [subscription, setSubscription] = useState(null);
    const { currentUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false); // Stavová premenná pre zobrazenie správy "Moment..."
    const [isAgreed, setIsAgreed] = useState(false);
    const { account_type } = useAuth(); // Destructure account_type from the AuthContext
    const [showModal, setShowModal] = useState(false);
    const [isProductsLoaded, setIsProductsLoaded] = useState(false);

    

    useEffect(() => {
        const handleClickOutside = event => {
          const modal = document.getElementById('myModal');
          if (showModal && modal && !modal.contains(event.target)) {
            setShowModal(false);
          }
        };
    
        const handleEscKey = event => {
          if (showModal && event.key === 'Escape') {
            setShowModal(false);
          }
        };
    
        document.addEventListener('click', handleClickOutside);
        document.addEventListener('keydown', handleEscKey);
    
        return () => {
          document.removeEventListener('click', handleClickOutside);
          document.removeEventListener('keydown', handleEscKey);
        };
      }, [showModal]);
    
      useEffect(() => {
        if (showModal) {
          const script = document.createElement('script');
          script.src = "https://assets.calendly.com/assets/external/widget.js";
          script.async = true;
          script.onload = () => {
            window.Calendly.initInlineWidget({
              //url: 'https://calendly.com/robert-jacko/30min?hide_event_type_details=1&hide_gdpr_banner=1',
              url: 'https://calendly.com/info-ml8/inolens-intro/30min?hide_event_type_details=1&hide_gdpr_banner=1',
              parentElement: document.getElementById('calendlyContainer'),
              prefill: {},
              utm: {}
            });
          };
          document.body.appendChild(script);
    
          return () => {
            document.body.removeChild(script);
          };
        }
      }, [showModal]);

    useEffect(() => {
        const loadProducts = async () => {
            const snapshot = await db.collection('products').where('active', '==', true).get();
            const products = {};
            const productPromises = [];
            snapshot.forEach(productDoc => {
                const loadPrices = async () => {
                    products[productDoc.id] = productDoc.data();
                    const priceSnapshot = await productDoc.ref.collection('prices').get();
                    priceSnapshot.forEach(priceDoc => {
                        const priceData = priceDoc.data();
                        products[productDoc.id].prices = {
                            priceId: priceDoc.id,
                            priceData,
                            formattedPrice: (priceData.unit_amount / 100).toFixed(2) + "€"
                        };
                    });
                    const imageUrls = productDoc.data().images;
                    products[productDoc.id].imageUrl = imageUrls ? imageUrls[0] : null;
                };
                productPromises.push(loadPrices());
            });
    
            await Promise.all(productPromises);
            setProducts(products);
            setIsProductsLoaded(true);
        };
    
        loadProducts();
    }, []);
    

      const checkOut = async(priceId) => {


        setIsLoading(true);
      }

      const parseDescription = (description) => {
        const regex = /(\d+ \w+)/g;
        return description.split(regex).map((text, index) => {
            // Ak je text v tvare číslo + slovo, použijeme tučný štýl
            return regex.test(text) ? <span key={index} className="bold-text">{text}</span> : text;
        });
    };
 
 const handleRedirectHome = () => {
      window.location.href = '/';  
   }

 const handleRedirectRegister = () => {
    window.location.href = '/register';  
  }
   
 const periodToText = (period) => {
      switch(period) {
          case "3":
              return "/ každé 3 mesiace";
          case "6":
              return "pol rok";
          case "12":
              return "rok";
          default:
              return "/ mesačne";
      }
  }
  


    return (
        <div>
            <NavBar />
            <div className="page">
                {!account_type ? (
                    
                    Object.entries(products)
                        .sort(([, productDataA], [, productDataB]) => {
                            const priceA = productDataA.prices ? productDataA.prices.priceData.unit_amount : Infinity;
                            const priceB = productDataB.prices ? productDataB.prices.priceData.unit_amount : Infinity;
                            return priceA - priceB;
                        })
                        .map(([productId, productData]) => {
                            const isCurrentPlan = productData?.name?.toLowerCase().includes(subscription?.role);
                            const price = productData.prices ? productData.prices.formattedPrice : "Cena nie je k dispozícii";
                            const imageUrl = productData.imageUrl;
                            const periodText = periodToText(productData?.metadata?.period);
    
                            return (
                                <div className="products-container">
                                <div className="plan-card center" key={productId}>
                                    <h2>{productData.name}</h2>
                                    {imageUrl && <img src={imageUrl} alt={productData.name} />}
                                    <p>
                                        {parseDescription(productData.description)}
                                    </p>
                                    <p className="price-text">
                                      <span >{price}</span>
                                      <span className="period">{periodText}</span>
                                    </p>
                                    {isLoading ? (
                                        <p>Moment...</p>
                                    ) : (
                                        <br />
                                    )}
                                </div>

                                </div>
                            );
                        })
                ) : (

                    <div className="page">
                    </div>
                )}
                <div>
                    <br /><br />
                </div>
                
            </div>

            

            <div className="page" >
            <div className="center" >
            {!isProductsLoaded ? (
                <p>Načítavam predplatné...</p>
            ) : (
              <div className="welcomePageBlock animated">
              <h1 id="title" className="center">O predplatnom sa môžete dozvedieť viac aj pomocou DEMO ukážky</h1>
              
              

              <p className="center">Náš produktový špecialista Vám prostredníctvom videohovoru cca za 20 minút ukáže ako aplikacia funguje a budete mať priestor sa priamo opýtať na všetko, čo Vás bude o tejto službe zaujímať.
              </p>

              <p className="center">Na vyskúšanie si služby pre Vás máme 20 kreditov zadarmo. Stačí ak si v aplikácii vytvoríte bezplatný účet. Nie je potrebná žiadna platobná karta ani iné citlivé údaje. Službu si vyskúšajte hneď založením účtu na tomto odkaze:
              </p>
              
              

              <div style={{ display: 'flex', justifyContent: 'center', }}>
                        
                        <button onClick={() => setShowModal(true)} className="login-form-btn round-button">
                            Rezervovať si DEMO
                        </button>

                        <button onClick={handleRedirectRegister} className="login-form-btn round-button">
                            Založiť si účet zdarma
                        </button>
                        <br />
              </div>
            </div>
            )}
                

            


                
               </div>
               </div>

               <div>
                    <br /><br />
                </div>
      
                {showModal && (
                  <div id="myModal" className="modal">
                    <div className="modal-content">
                      <p className="center" style={{ color: 'white', fontSize: '13pt' }}>
                        Vyberte si dátum a čas na živú prezentáciu tejto aplikácie. Ukážeme Vám ako pomocou tohto nástroja jednoducho vytvoríte obsah pre rôzne druhy nehnuteľností. Odhadovaný čas je 10 - 20 minút. Vybrať si tiež môžete medzi videohovorom, alebo klasickým telefonátom.
                      </p>

                      

                      <div id="calendlyContainer" style={{ width: '100%', height: '720px', position: 'auto' }}></div>
                      <button onClick={() => setShowModal(false)} className="login-form-btn">
                        Zatvoriť
                      </button>
                    </div>
                  </div>
                )}
            
            <Footer />
        </div>
    );
    
    
    
}

export default Home
 