import React, { useEffect, useState } from 'react';
import { auth } from '../firebase'
import firebase from '../firebase';
import './stripe.css'
import { useAuth } from '../contexts/AuthContext';
import { loadStripe } from "@stripe/stripe-js";
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import StripeCustomerPortal from '../stripe/StripeCustomerPortal';
import InvoicesList from '../components/InvoicesList';


const Home = () => {
    const db = firebase.firestore();
    const [products, setProducts] = useState([])
    const [subscription, setSubscription] = useState(null);
    const { currentUser } = useAuth();
    const [isLoading, setIsLoading] = useState(false); // Stavová premenná pre zobrazenie správy "Moment..."
    const [isAgreed, setIsAgreed] = useState(false);
    const { account_type } = useAuth(); // Destructure account_type from the AuthContext

    
    useEffect(() => {
        //console.log('Začiatok vykonávania useEffect');
    
        db.collection("users").doc(currentUser.uid).collection("subscriptions").get().then(snapshot => {
            snapshot.forEach(subscription => {
                console.log('subscription', subscription.data())
                setSubscription({
                    role: subscription.data().role,
                    current_period_start: subscription.data().current_period_start,
                    current_period_end: subscription.data().current_period_end
                });
            });
        }).then(() => {
           // console.log('Koniec vykonávania useEffect');
        });
    }, []);
    

    useEffect(() => {
        const loadProducts = async () => {
            const snapshot = await db.collection('products').where('active', '==', true).get();
            const products = {};
            const productPromises = [];
            snapshot.forEach(productDoc => {
                const loadPrices = async () => {
                    products[productDoc.id] = productDoc.data();
                    const priceSnapshot = await productDoc.ref.collection('prices').get();
                    priceSnapshot.forEach(priceDoc => {
                        const priceData = priceDoc.data();
                        products[productDoc.id].prices = {
                            priceId: priceDoc.id,
                            priceData,
                            formattedPrice: (priceData.unit_amount / 100).toFixed(2) + "€"
                        };
                    });
                    const imageUrls = productDoc.data().images;
                    products[productDoc.id].imageUrl = imageUrls ? imageUrls[0] : null;
                };
                productPromises.push(loadPrices());
            });
    
            await Promise.all(productPromises);
            setProducts(products);
        };
    
        loadProducts();
    }, []);
    

      const checkOut = async(priceId) => {


        setIsLoading(true);
        const docRef = await db.collection("users").doc(currentUser.uid).collection("checkout_sessions").add({
            price: priceId,
            success_url: window.location.origin,
            cancel_url: window.location.origin,
            //tax_id_collection: "enabled",
            //phone_number_collection: {enabled: true}, 
            automatic_tax: {enabled: true},           
        });

        docRef.onSnapshot(async(snap)=>{
            const {error, sessionId} = snap.data();
            if (error){
                alert(error.message)
            }
            if (sessionId) {
                const stripe = await loadStripe("pk_live_51NIHaSGIvpF2t1WyRPbsYEQRinjbUgeXej11iT4M9I5hcIySJ56JWBS2AzUwi2Zg1x9KKyAeyCuHmrqKlzum4nYG00X6HYqszf")
                stripe.redirectToCheckout({sessionId})
            }
        })

      }

      const parseDescription = (description) => {
        const regex = /(\d+ \w+)/g;
        return description.split(regex).map((text, index) => {
            // Ak je text v tvare číslo + slovo, použijeme tučný štýl
            return regex.test(text) ? <span key={index} className="bold-text">{text}</span> : text;
        });
    };

    const periodToText = (period) => {
        switch(period) {
            case "3":
                return "za 3 mesiace";
            case "6":
                return "pol rok";
            case "12":
                return "rok";
            default:
                return "mesačne";
        }
    }


    return (
        <div>
            <NavBar />
            <div className="page">
                {!account_type || account_type === "Free" ? (
                    
                    Object.entries(products)
                        .sort(([, productDataA], [, productDataB]) => {
                            const priceA = productDataA.prices ? productDataA.prices.priceData.unit_amount : Infinity;
                            const priceB = productDataB.prices ? productDataB.prices.priceData.unit_amount : Infinity;
                            return priceA - priceB;
                        })
                        .map(([productId, productData]) => {
                            const isCurrentPlan = productData?.name?.toLowerCase().includes(subscription?.role);
                            const price = productData.prices ? productData.prices.formattedPrice : "Cena nie je k dispozícii";
                            const imageUrl = productData.imageUrl;
                            const periodText = periodToText(productData?.metadata?.period);
    
                            return (
                                <div className="products-container">
                                <div className="plan-card center" key={productId}>
                                    <h2>{productData.name}</h2>
                                    {imageUrl && <img src={imageUrl} alt={productData.name} />}
                                    <p>
                                        {parseDescription(productData.description)}
                                    </p>
                                    <p className="price-text">{price} / {periodText}</p>
                                    {isLoading ? (
                                        <p>Moment...</p>
                                    ) : (
                                        <button disabled={isCurrentPlan} onClick={() => checkOut(productData.prices.priceId)}>Predplatiť</button>
                                    )}
                                </div>
                                </div>
                            );
                        })
                ) : (

                    <div className="page">
                        <div className="plan-card center">
                            <h3>Vaše aktuálne predplatné je: {account_type}</h3>
                            {<StripeCustomerPortal /> }
                            <hr />
                            <h3>Vaše faktúry:</h3>
                            {<InvoicesList /> }
                        </div>
                    </div>
                )}
                <div>
                    <br /><br />
                </div>
            </div>
            <Footer />
        </div>
    );
    
    
    
}

export default Home
 