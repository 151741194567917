import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import '../css/general-page.css';
import NavBar from '../components/NavBar';
import CreditStatus from '../components/CreditStatus';
import DaysToReset from '../components/DaysToReset';
import InvitationsStatus from '../components/InvitationsStatus';
import { Navigate } from 'react-router-dom';
import InviteForm from "../components/InviteForm";
import InvitationsTable from "../components/InvitationsTable";
import JoinTeamForm from '../components/JoinTeamForm';
import ParentAccountCreditStatus from '../components/ParentAccountCreditStatus';

import Footer from '../components/Footer';


import { useState } from 'react';
import BillingDetailsModal from '../components/BillingDetailsModal';

function UserDetail() {
  const { currentUser, isSubUser, account_type, account_name } = useAuth();


  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    
    <div >
      <NavBar />
      <div className="login-page">
        <div className="app-card">
          <h1>Detail účtu</h1>
          <CreditStatus userId={currentUser.uid} />
          { !isSubUser && <InvitationsStatus userId={currentUser.uid} /> }
          <p>Názov účtu: {account_name}</p>
          <p>Typ účtu: {account_type}</p>
          <p>Email: {currentUser.email}</p>
          <hr />
          {<BillingDetailsModal /> }
          {<InviteForm /> }
          {<InvitationsTable /> }
          {<JoinTeamForm />}
          {<ParentAccountCreditStatus/>}
        </div>
      </div>
      <div >
       <br /><br />
      </div>
      <Footer />
    </div>
  );
};

export default UserDetail;

